// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-network {
  margin: 80px auto;
  width: 30%;
  min-width: 360px;
}
.no-network > div {
  text-align: center;
  padding: 10px;
  font-size: 16px;
}
.no-network > div > .no-network-header {
  color: #033a6a;
  margin-bottom: 1.5rem;
}
.no-network > div > .no-network-header > i {
  font-size: 3rem;
}
.no-network > div > .no-network-header > h3 {
  margin-top: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/no-network/no-network.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,UAAA;EACA,gBAAA;AACF;AACE;EACE,kBAAA;EACA,aAAA;EACA,eAAA;AACJ;AACI;EACE,cAAA;EACA,qBAAA;AACN;AACM;EACE,eAAA;AACR;AAEM;EACE,gBAAA;AAAR","sourcesContent":[".no-network {\n  margin: 80px auto;\n  width: 30%;\n  min-width: 360px;\n\n  & > div {\n    text-align: center;\n    padding: 10px;\n    font-size: 16px;\n\n    & > .no-network-header {\n      color: #033a6a;\n      margin-bottom: 1.5rem;\n\n      & > i {\n        font-size: 3rem;\n      }\n\n      & > h3 {\n        margin-top: 1rem;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
