import { Component, Input } from '@angular/core';
import { Status } from '@shared/models/status-type.model';
@Component({
  selector: 'information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  DEFAULT_ICON = 'cui-scania-warning';
  @Input() icon: string;
  @Input() headerLabel: string;
  @Input() label: string;
  @Input() type: Status = Status.DEFAULT;
  /** `any` like `TranslateDirective.translateParams` type */
  @Input() translateParams: any;
  @Input() headerTranslateParams: any;
}
