import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

export interface RadioGroupConfig {
  defaultValue?: string;
  alignment?: 'horizontal' | 'vertical';
  options?: RadioGroupOption[];
}
export interface RadioGroupOption {
  label?: string;
  value?: string;
  disabled?: boolean;
}
@Component({
  selector: 'radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: RadioGroupComponent }]
})
export class RadioGroupComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() config: RadioGroupConfig;
  @Input() id: string;

  radioControl: FormControl;
  /** we use a separate flag instead of enabling / disabling the form control
   * because the input would ignore any option config after the form control
   * would be re-enabled, causing options that should be disabled by config to also be enabled */
  isFormDisabled = false;

  private sub: Subscription;

  onChange: (value: string) => void;
  onTouched: () => void;

  constructor() {}

  writeValue(value: string) {
    this.radioControl.patchValue(value ?? this.config.defaultValue, { emitEvent: false });
  }

  registerOnChange(onChange: (value: any) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isFormDisabled = isDisabled;
  }

  private emitValue(value: string) {
    if (this.onChange) {
      this.onChange(value);
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }

  markAsTouched() {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  ngOnInit() {
    this.radioControl = new FormControl(this.config.defaultValue);
    this.sub = this.radioControl.valueChanges.subscribe((value) => this.emitValue(value));
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
