import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NoNetworkService {
  networkAvailable = true;
  networkAvailabilityChange: Subject<boolean> = new Subject<boolean>();

  constructor() {
    this.networkAvailabilityChange.subscribe((value) => (this.networkAvailable = value));
    // check if there is no internet when the app starts (useful at refresh)
    this.networkAvailable = navigator.onLine;
    // event listeners for network connection
    window.addEventListener('offline', () => this.networkAvailabilityChange.next(false));

    window.addEventListener('online', () => this.networkAvailabilityChange.next(true));
  }

  isNetworkAvailable = () => this.networkAvailable;
}
