import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

export interface Time {
  hours: number;
  minutes: number;
  seconds?: number;
}

@Component({
  selector: 'time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: TimeInputComponent }]
})
export class TimeInputComponent implements ControlValueAccessor, OnChanges, OnInit, OnDestroy {
  DEFAULT_MIN_TIME: Time = { hours: 0, minutes: 0, seconds: 0 };

  timeGroup: FormGroup<{
    hours: FormControl<number>;
    minutes: FormControl<number>;
    seconds: FormControl<number>;
  }>;

  @Input() min: Time = this.DEFAULT_MIN_TIME;
  @Input() max: Time;

  @Input() seconds = false;

  @Input() showConfirmCheck = true;

  @Output() confirm: EventEmitter<void> = new EventEmitter();

  minHours = this.min.hours;
  minMinutes = this.min.minutes;
  minSeconds = this.min.seconds;

  private sub: Subscription;
  onChange: (value: Time) => void;
  onTouched: () => void;

  constructor() {}

  /** return positive value if a > b, 0 if a == b, and < 0 if a < b */
  compareTime(a: Time, b: Time) {
    const dateA = new Date();
    dateA.setHours(a.hours, b.minutes, b.seconds || 0);

    const dateB = new Date(dateA);
    dateB.setHours(b.hours, b.minutes, b.seconds || 0);

    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }

    return 0;
  }

  updateMinTime() {
    if (this.compareTime(this.min, this.DEFAULT_MIN_TIME) === 0) {
      this.minHours = this.DEFAULT_MIN_TIME.hours;
      this.minMinutes = this.DEFAULT_MIN_TIME.minutes;
      this.minSeconds = this.DEFAULT_MIN_TIME.seconds;
      return;
    }

    // set the minimum of each time unit by comparing the current time -1 unit with the minimum.
    // if current time -1 unit would be earlier than minimum time, current value for unit is considered minimum.
    const selectedTime = this.timeGroup?.getRawValue() || this.DEFAULT_MIN_TIME;

    // set min hours
    const selectedTimeOnHourEarlier = { ...selectedTime, hours: selectedTime.hours - 1 };
    if (this.compareTime(selectedTimeOnHourEarlier, this.min) < 0) {
      this.minHours = selectedTime.hours;
    } else {
      this.minHours = this.min.hours;
    }

    // set min minutes
    const selectedTimeOneMinuteEarlier = { ...selectedTime, minutes: selectedTime.minutes - 1 };
    if (this.compareTime(selectedTimeOneMinuteEarlier, this.min) < 0) {
      this.minMinutes = selectedTime.minutes;
    } else if (selectedTime.hours === this.min.hours) {
      this.minMinutes = this.min.minutes;
    } else {
      this.minMinutes = this.DEFAULT_MIN_TIME.minutes;
    }

    // set min seconds
    const selectedTimeOneSecondEarlier = { ...selectedTime, seconds: selectedTime.seconds - 1 };
    if (this.compareTime(selectedTimeOneSecondEarlier, this.min) < 0) {
      this.minSeconds = selectedTime.seconds;
    } else if (selectedTime.hours === this.min.hours && selectedTime.minutes === this.min.minutes) {
      this.minSeconds = this.min.seconds;
    } else {
      this.minSeconds = this.DEFAULT_MIN_TIME.seconds;
    }
  }

  emitValue(value: Time) {
    if (this.onChange) {
      this.onChange(value);
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }

  writeValue(value: Time): void {
    this.timeGroup.setValue(
      { hours: value.hours, minutes: value.minutes, seconds: isNaN(value.seconds) ? 0 : value.seconds },
      { emitEvent: false }
    );
  }

  registerOnChange(fn: (value: Time) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    this.timeGroup = new FormGroup({
      hours: new FormControl(this.min?.hours || 0),
      minutes: new FormControl(this.min?.minutes || 0),
      seconds: new FormControl(this.min?.seconds || 0)
    });

    this.updateMinTime();

    this.sub = this.timeGroup.valueChanges.subscribe((time: Time) => {
      this.updateMinTime();
      this.emitValue(time);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if min time input changes while we have some time value set
    // reset current value to min time if lower
    if (changes['min']?.currentValue) {
      const min = changes['min'].currentValue as Time;
      if (this.timeGroup && this.compareTime(this.timeGroup.getRawValue(), min) < 0) {
        this.timeGroup.patchValue(min);
      }
      this.updateMinTime();
    }
  }

  formatNumber(value: number): string {
    return value.toString().padStart(2, '0'); // format the number with leading zeros
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
