import { inject, Injectable } from '@angular/core';
import { loadAdobeAnalytics } from '@core/init/adobe-analytics-loader';
import { Environment, ENVIRONMENT_TOKEN } from '@core/models/environment.model';
import { UserProfile } from '@core/models/user-profile.model';
import { AnalyticsEventData, GeneralAnalyticsEvent } from '@core/services/analytics/analytics';
import { CookieService } from '@core/services/cookie/cookie.service';
import { Guid } from 'guid-typescript';

export interface AdobeAnalyticsEventData extends AnalyticsEventData {
  session: {
    sessionReference: string;
    customerReference: string;
    language: string;
    customerName: string;
    timezone: string;
    country: string;
    screenSize: {
      width: number;
      height: number;
    };
    userAgent: string;
  };
}

export interface AdobeAnalyticsPageEventData extends AnalyticsEventData {
  viewContext: string;
  route: string;
}

@Injectable({ providedIn: 'root' })
export class AdobeAnalyticsService {
  private sessionReference: Guid;
  private hasCookieConsent = false;
  private env: Environment;
  private cookieService: CookieService;

  private userProfile: UserProfile;

  constructor() {
    this.cookieService = inject(CookieService);
    this.sessionReference = Guid.create();
    this.env = inject(ENVIRONMENT_TOKEN);
  }

  init(userProfile: UserProfile) {
    this.userProfile = userProfile;
    this.hasCookieConsent = this.cookieService.getConsentCookiePreferences()?.performance;
    if (!this.hasCookieConsent) {
      return;
    }
    // reuse adobe analytics loader that was previously used as an app initializer
    loadAdobeAnalytics(this.env)();
  }

  pushEvent(eventName: string, eventData: AnalyticsEventData = {}) {
    if (!this.hasCookieConsent) {
      return;
    }

    const decoratedEvent: AdobeAnalyticsEventData = {
      ...eventData,
      session: {
        // append session data to the event
        country: this.userProfile.customer.country,
        customerName: this.userProfile.customer.name,
        customerReference: this.userProfile.customer.externalCustomerReference,
        language: this.userProfile.language,
        screenSize: { width: window.innerWidth, height: window.innerHeight },
        sessionReference: this.sessionReference.toString(),
        timezone: this.userProfile.customer.timeZone,
        userAgent: window.navigator.userAgent
      }
    };

    window.adobeDataLayer.push({
      event: eventName,
      // analytics team asked to change the `eventInfo` property to `pageInfo` for `page_view` events
      ...(eventName === GeneralAnalyticsEvent.PAGE_VIEW ? { pageInfo: decoratedEvent } : { eventInfo: decoratedEvent })
    });
  }

  pushPageViewEvent(viewContext: string) {
    return this.pushEvent(GeneralAnalyticsEvent.PAGE_VIEW, {
      viewContext,
      route: window.location.href
    });
  }

  pushConsoleErrorEvent(error: Error) {
    return this.pushEvent(GeneralAnalyticsEvent.CONSOLE_ERROR, {
      errorMessage: String(error),
      route: window.location.href
    });
  }
}
