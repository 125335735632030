export const defectType = {
  status: {
    unreviewed: 'Unreviewed',
    accepted: 'Accepted',
    rejected: 'Rejected',
    resolved: 'Resolved',
    booked: 'Booked'
  },
  statusId: {
    unreviewed: 0x1,
    accepted: 0x2,
    rejected: 0x4,
    resolved: 0x8,
    booked: 0x10
  },
  exception: {
    none: 'None',
    lampBrakeLining: 'WarningLampBrakeLining',
    highEngineCoolantTemperature: 'WarningOfHighEngineCoolantTemperature',
    lowEngineCoolantWaterLevel: 'WarningLowEngineCoolantWaterLevel',
    lowEngineOilPressure: 'WarningLowEngineOilPressure',
    lowTirePressure: 'WarningLowTirePressure',
    lampLowUreaLevel: 'WarningLampLowUreaLevel',
    clutchOverload: 'WarningClutchOverload',
    seriousEngineProblem: 'WarningOfSeriousEngineProblem'
  },
  exceptionId: {
    none: 0,
    lampBrakeLining: 1,
    highEngineCoolantTemperature: 2,
    lowEngineCoolantWaterLevel: 3,
    lowEngineOilPressure: 4,
    lowTirePressure: 5,
    lampLowUreaLevel: 6,
    clutchOverload: 7,
    seriousEngineProblem: 8
  },
  severity: {
    none: 'None',
    minor: 'Minor',
    major: 'Major',
    critical: 'Critical'
  },
  severityId: {
    none: 0,
    minor: 1,
    major: 2,
    critical: 4
  }
};

export const severityOptions = [
  defectType.severity.none,
  defectType.severity.minor,
  defectType.severity.major,
  defectType.severity.critical
];

export const severityLabels = {
  [defectType.severity.critical]: 'Kritisk',
  [defectType.severity.major]: 'Allvarlig',
  [defectType.severity.minor]: 'Lindrig',
  [defectType.severity.none]: 'Ingen'
};
