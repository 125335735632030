import { HttpParams, HttpRequest } from '@angular/common/http';

export class RequestMapper {
  requestParamsMap: any = {};
  responseParamsMap: any = {};

  mapObject(baseObject: any, paramsMap: any) {
    return Object.keys(baseObject).reduce((accumulator, key) => {
      const renamedRequestParam = {
        [paramsMap[key] || key]: baseObject[key]
      };

      return {
        ...accumulator,
        ...renamedRequestParam
      };
    }, {});
  }
  mapRequest(req: HttpRequest<any>): HttpRequest<any> {
    const reqParams = {};
    req.params.keys().forEach((key) => {
      reqParams[key] = req.params.get(key);
    });
    const mappedParams = this.mapObject(reqParams, this.requestParamsMap);
    return req.clone({ params: new HttpParams({ fromObject: mappedParams }) });
  }

  mapResponse(data: any): any {
    // TODO: extend this method if the response mapping needs to go deeper than 1st level
    // TODO: data is HttpResponse so we can probably conditionally handling the response
    return data;
  }
}
