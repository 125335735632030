import { RequestMapper } from '@shared/mappers/mapper';

export class DefectsRequestMapper extends RequestMapper {
  override requestParamsMap: any = {
    status: 'DefectStatusFilter',
    requiresBooking: 'RequiresBooking',
    equipmentReference: 'EquipmentRef',
    getBookingInfo: 'GetBookingInfo',
    startDate: 'FromDate',
    endDate: 'ToDate',
    equipmentGroupReference: 'EquipmentGroupRef'
  };
}
