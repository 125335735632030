import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CUSTOM_MAT_FORMATS, CustomMatDateAdapter } from '@shared/utils/material-date-adapter';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export interface DateRange {
  start: Date | null;
  end: Date | null;
}

@Component({
  selector: 'date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: DateRangePickerComponent },
    { provide: DateAdapter, useClass: CustomMatDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_MAT_FORMATS }
  ]
})
export class DateRangePickerComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() placeholder = '';
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() showGreyInput = false;
  startDateControl: FormControl;
  endDateControl: FormControl;
  rangeGroup: FormGroup;
  private lastEmit: DateRange = { start: null, end: null };
  private rangeSubscription: Subscription;

  private onChange: (value: DateRange) => void;
  private onTouched: () => void;

  constructor() {}

  private emitValue(value: DateRange): void {
    // this is due to a bug of MatDateRangePicker, causing each input to fire multiple times when used with reactive forms
    // see https://github.com/angular/components/issues/19776 for details
    if (value.start === this.lastEmit.start && value.end === this.lastEmit.end) {
      return;
    }

    this.lastEmit = value;
    if (this.onChange) {
      this.onChange(value);
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }

  writeValue(value: DateRange): void {
    this.lastEmit = value;
    if (value) {
      this.rangeGroup.patchValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: (value: DateRange) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const start = new Date(year, month, 1);
    const end = new Date(year, month + 1, 0);

    // cache the default values as well
    this.lastEmit = { start, end };

    this.startDateControl = new FormControl(start, Validators.required);
    this.endDateControl = new FormControl(end, Validators.required);
    this.rangeGroup = new FormGroup({ start: this.startDateControl, end: this.endDateControl });

    this.rangeSubscription = this.rangeGroup.valueChanges.pipe(debounceTime(500)).subscribe((range) => {
      this.emitValue(range);
    });
  }

  ngOnDestroy(): void {
    this.rangeSubscription.unsubscribe();
  }
}
