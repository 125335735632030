import { RequestMapper } from '@shared/mappers/mapper';

export class ServiceActivityRequestMapper extends RequestMapper {
  override requestParamsMap: any = {
    dueStatus: 'DueStatus',
    requiresBooking: 'RequiresBooking',
    equipmentReference: 'ExternalEquipmentReference',
    equipmentGroupReference: 'EquipmentGroupReference',
    endDate: 'EndDate',
    startDate: 'StartDate'
  };
}
