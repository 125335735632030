import { Component, Input } from '@angular/core';
import { Status } from '@shared/models/status-type.model';

@Component({
  selector: 'badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent {
  @Input() type: Status = Status.DEFAULT;
  @Input() label = '';
  @Input() description = '';
  @Input() size: 'sm' | 'md' | 'lg' = 'sm';

  constructor() {}
}
