export const servicePlanningConstants = {
  table: {
    pageSize: 20
  },
  checklist: {
    maxDescriptionLength: 500
  },
  booking: {
    // taken from app-config.util.js in fmp-frontend
    defaultDropOffInHours: 336, // was called `defaultDate`  this is in hrs
    defaultPickUpInHours: 338, // was called `defaultDateTime`
    emailAddressMaxLength: 60,
    defaultEstimatedDuration: 120, // in mints if duration is 0
    /** fetch ongoing services
     * that have not started earlier than x months ago */
    monthsUntilAutomaticallyPerformed: 5
  },
  defect: {
    headerMaxLength: 50,
    reportedByMaxLength: 70,
    descriptionMaxLength: 700,
    noActionReasonMaxLength: 50,
    maxUploadCount: 5,
    maxUploadSize: 20 * 1000000, // 20 megabytes
    imageTimeout: 40 * 1000 // milliseconds
  },
  settings: {
    autoSaveDelay: 500,
    scopeBroadcastDelay: 500,
    dateTimePickerDisplayDelay: 1200,
    vinLength: 17
  },
  customerSettings: {
    //  Weeks
    minServiceEventDueThreshold: 1,
    maxServiceEventDueThreshold: 8,
    minServiceEventMinProximity: 1,
    maxServiceEventMinProximity: 16
  }
};

export const gasUnitTypes = [
  {
    value: 'Kg',
    translation: 'Kg'
  },
  {
    value: 'Nm3',
    translation: 'Nm3'
  }
];

export const unitTypes = [
  {
    value: 'Imperial|Distance/Fuel',
    translation: 'BrittiskaEnheter_Miles_Mpg_'
  },
  {
    value: 'Metric|Distance/Fuel',
    translation: 'MetriskaEnheter_Km_Km_L_'
  },
  {
    value: `Metric|Fuel/Distance`,
    translation: 'MetriskaEnheter_Km_L_100Km_'
  }
];

export const vehicleIdentifiers = [
  {
    value: 'Vehicle alias',
    translation: 'Alias'
  },
  {
    value: 'Registration number',
    translation: 'Registreringsnummer'
  },
  {
    value: 'Chassi number',
    translation: 'Chassinummer'
  }
];

export const vehicleIdentifierMap = {
  alias: 'Vehicle alias',
  registrationNumber: 'Registration number',
  chassisNumber: 'Chassi number'
};

export const pagePermissions = {
  Fordonsposition: {
    full: ['OfficePersonal', 'ServiceManager', 'Viewer', 'SuperUser', 'Signatory'],
    limited: ['Public'],
    edit: ['OfficePersonal', 'ServiceManager', 'SuperUser', 'Signatory']
  },
  Serviceplanering: {
    full: [
      'Driver',
      'OfficePersonal',
      'ServiceManager',
      'Viewer',
      'Public',
      'SuperUser',
      'Signatory',
      'ExternalDriver'
    ],
    limited: ['MonitoringReportViewer'],
    edit: ['Driver', 'OfficePersonal', 'ServiceManager', 'Viewer', 'ExternalDriver']
  },
  Förarutvärdering: {
    full: ['OfficePersonal', 'SuperUser', 'Signatory'],
    limited: [],
    edit: []
  },
  Fordonsuppföljning: {
    full: ['Driver', 'OfficePersonal', 'SuperUser', 'Signatory'],
    limited: ['ServiceManager', 'Viewer'],
    edit: []
  },
  Körtid: {
    full: ['OfficePersonal', 'ServiceManager', 'Viewer', 'SuperUser', 'Signatory'],
    limited: [],
    edit: []
  },
  Adressbok: {
    full: ['Driver', 'OfficePersonal', 'ServiceManager', 'Viewer', 'SuperUser', 'Signatory'],
    limited: [],
    edit: ['OfficePersonal', 'ServiceManager', 'SuperUser', 'Signatory']
  },
  Utrustning: {
    full: [
      'Driver',
      'OfficePersonal',
      'ServiceManager',
      'Viewer',
      'Public',
      'SuperUser',
      'Signatory',
      'ExternalDriver',
      'MonitoringReportViewer'
    ],
    limited: [],
    edit: ['OfficePersonal', 'ServiceManager', 'SuperUser', 'Signatory']
  },
  MinPersonalprofil: {
    full: [
      'Driver',
      'OfficePersonal',
      'ServiceManager',
      'Viewer',
      'Public',
      'SuperUser',
      'Signatory',
      'ExternalDriver'
    ],
    limited: [],
    edit: [
      'Driver',
      'OfficePersonal',
      'ServiceManager',
      'Viewer',
      'Public',
      'SuperUser',
      'Signatory',
      'ExternalDriver',
      'MonitoringReportViewer'
    ]
  },
  Avtal: {
    full: ['OfficePersonal'],
    limited: [],
    edit: []
  }
};

export const actionType = {
  delete: 'delete',
  preventDelete: 'coachingDeletionPrevented',
  cancel: 'cancel'
};

export enum Unit {
  METRIC = 'Metric',
  IMPERIAL = 'Imperial'
}

// '1975-01-01'
export const earliestAllowedDateValue = 157766400000;

export const currentFmpBaseUrl = 'https://fmpnextgen.scania.com/#/positioning/fleet-position/vehicle-list/vehicle';
export const TEST_EQUIPMENT = 'ba9fa1c0-3a90-43d4-8ce2-9f1325e75231';
