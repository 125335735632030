import { inject, Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { ENVIRONMENT_TOKEN } from '@core/models/environment.model';
import H from '@here/maps-api-for-javascript';
import '@here/maps-api-for-javascript/bin/mapsjs.bundle.harp.js';
import { TranslateService } from '@ngx-translate/core';
import {
  DEFAULT_ICON_SVG,
  MAP_ENGINE_TYPE,
  MAP_LANGUAGES,
  MAP_TILE,
  SCALEBAR_STYLE
} from '@shared/components/here-map/map.constants';

export interface HereMapLocation<T = any> extends H.geo.IPoint {
  data?: T;
}

export const MARKER_CONTAINER_CLASS_NAME = 'here-map-marker-container';
export const MARKER_CONTAINER_SELECTED_CLASS_NAME = 'here-map-marker-selected';
export const CLUSTER_CONTAINER_CLASS_NAME = 'here-map-cluster-marker-container';

@Injectable()
export class HereMapService {
  private env = inject(ENVIRONMENT_TOKEN);
  private translate = inject(TranslateService);

  /** keep track of created dom markers so we can mark them as selected accordingly */
  private domMarkerContainersMap = new Map<string, Element>();

  private getDomMarkerContainer(location: HereMapLocation): Element {
    const key = '' + location.lat + location.lng;
    return this.domMarkerContainersMap.get(key);
  }

  private setDomMarkerContainer(location: HereMapLocation, markerContainer: Element): void {
    const key = '' + location.lat + location.lng;
    this.domMarkerContainersMap.set(key, markerContainer);
  }

  private removeDomMarkerContainer(location: HereMapLocation): void {
    const key = '' + location.lat + location.lng;
    this.domMarkerContainersMap.delete(key);
  }

  private markLocationSelected(location: HereMapLocation) {
    const markerContainer = this.getDomMarkerContainer(location);
    if (!markerContainer) {
      return;
    }

    this.domMarkerContainersMap.forEach((value) => value.classList.remove(MARKER_CONTAINER_SELECTED_CLASS_NAME));
    markerContainer.classList.add(MARKER_CONTAINER_SELECTED_CLASS_NAME);
  }

  public createMap(container: HTMLElement, center: HereMapLocation): H.Map {
    const currentLang = MAP_LANGUAGES.find((lang) => lang.Culture === this.translate.currentLang);
    const platform = new H.service.Platform({ apikey: this.env.hereMapsApiKey });
    const tileService: H.service.rasterTile.Service = platform.getRasterTileService({
      format: 'png8',
      projection: 'mc',
      queryParams: { pois: 'enabled', ppi: 100, style: 'explore.day', lang: currentLang?.Subtag || 'en' }
    });

    const tileProvider = new H.service.rasterTile.Provider(tileService, { engineType: MAP_ENGINE_TYPE });

    const baseLayer = new H.map.layer.TileLayer(tileProvider, { min: 3 });

    const map = new H.Map(container, baseLayer, {
      center,
      pixelRatio: window.devicePixelRatio,
      engineType: MAP_ENGINE_TYPE,
      zoom: 5
    });

    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
    behavior.disable(
      H.mapevents.Behavior.Feature.FRACTIONAL_ZOOM |
        H.mapevents.Behavior.Feature.TILT |
        H.mapevents.Behavior.Feature.HEADING
    );

    // remove map UI zoom and settings controls
    const mapUI = new H.ui.UI(map, { locale: currentLang?.HereUiCode || 'en-US', scalebar: true });

    // style scalebar
    const scalebar = mapUI.getControl('scalebar');
    scalebar.setAlignment(H.ui.LayoutAlignment.BOTTOM_RIGHT);
    scalebar.getElement().style.position = SCALEBAR_STYLE.position;
    scalebar.getElement().style.bottom = SCALEBAR_STYLE.bottom;
    scalebar.getElement().style.right = SCALEBAR_STYLE.right;

    return map;
  }

  private createDomMarker(location: HereMapLocation, markerContainer: HTMLElement, minZoom?: number): H.map.DomMarker {
    const icon = new H.map.DomIcon(markerContainer, {
      onAttach: (element) => this.setDomMarkerContainer(location, element),
      onDetach: () => this.removeDomMarkerContainer(location)
    });

    const marker = new H.map.DomMarker(location as H.geo.IPoint, { icon, data: location.data, min: minZoom });
    marker.addEventListener('tap', () => this.markLocationSelected(location));

    return marker;
  }

  private createDomClusterMarker(cluster: H.clustering.ICluster, clusterContainer: HTMLElement): H.map.DomMarker {
    const icon = new H.map.DomIcon(clusterContainer);
    const position = cluster.getPosition();
    return new H.map.DomMarker(position, {
      icon,
      data: cluster,
      // set min and max zoom levels, otherwise the cluster marker will always be visible
      min: cluster.getMinZoom(),
      max: cluster.getMaxZoom()
    });
  }

  public createDefaultMarker(location: HereMapLocation, minZoom?: number): H.map.Marker {
    const icon = new H.map.DomIcon(DEFAULT_ICON_SVG);
    return new H.map.DomMarker(location as H.geo.IPoint, { icon, data: location.data, min: minZoom });
  }

  public createCustomMarker(
    location: HereMapLocation,
    templateRef: TemplateRef<any>,
    viewContainerRef: ViewContainerRef,
    minZoom?: number
  ): H.map.Marker {
    const markerContainer = document.createElement('div');
    markerContainer.classList.add(MARKER_CONTAINER_CLASS_NAME);

    const embeddedView = viewContainerRef.createEmbeddedView(templateRef, { $implicit: location.data });
    embeddedView.detectChanges();
    embeddedView.rootNodes.forEach((node) => markerContainer.appendChild(node));

    return this.createDomMarker(location, markerContainer, minZoom);
  }

  createDefaultClusterMarker(cluster: H.clustering.ICluster): H.map.Marker {
    const clusterContainer = document.createElement('div');
    clusterContainer.classList.add(CLUSTER_CONTAINER_CLASS_NAME);
    clusterContainer.innerHTML = `<div class="default-cluster-icon-container">${cluster.getWeight()}</div>`;

    return this.createDomClusterMarker(cluster, clusterContainer);
  }

  createCustomClusterMarker(
    cluster: H.clustering.ICluster,
    templateRef: TemplateRef<any>,
    viewContainer: ViewContainerRef
  ): H.map.Marker {
    const clusterContainer = document.createElement('div');
    clusterContainer.classList.add(CLUSTER_CONTAINER_CLASS_NAME);

    const embeddedView = viewContainer.createEmbeddedView(templateRef, { $implicit: { weight: cluster.getWeight() } });
    embeddedView.detectChanges();
    embeddedView.rootNodes.forEach((node) => clusterContainer.appendChild(node));

    return this.createDomClusterMarker(cluster, clusterContainer);
  }

  createClusterLayer(locations: HereMapLocation[], theme: H.clustering.ITheme): H.map.layer.ObjectLayer {
    const dataPoints = locations.map((location) => new H.clustering.DataPoint(location.lat, location.lng, 1, location));

    const clusterProvider = new H.clustering.Provider(dataPoints, {
      clusteringOptions: {
        eps: 32, // Maximum radius (px) to cluster neighbours
        minWeight: 2, // Minimum total weight of neighbours to form a cluster (default weight is 1)
        strategy: H.clustering.Provider.Strategy.FASTGRID
      },
      theme
    });

    return new H.map.layer.ObjectLayer(clusterProvider);
  }
}
