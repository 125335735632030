import { UserProfile } from '@core/models/user-profile.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';

@Injectable({
  providedIn: 'root'
})
export class UserStoreService {
  /** observable that will emit a flag specifying if the user is authenticated or not */
  public isAuthenticated$ = new BehaviorSubject<boolean>(false);
  /** observable that will emit the current user profile or null if unauthenticated */
  public profile$ = new BehaviorSubject<UserProfile>(null);
  /** observable that will emit an array of features enabled for current session, or null if unauthenticated */
  public features$ = new BehaviorSubject<string[]>([]);

  /** observable that will emit a LaunchDarkly flag set for the current context or null if not initialized */
  public ldFlags$ = new BehaviorSubject<LDFlagSet>(null);

  /** Current value of isAuthenticated flag */
  public get isAuthenticated(): boolean {
    return this.isAuthenticated$.getValue();
  }

  /** current value of the userProfile subject */
  public get profile(): UserProfile {
    return this.profile$.getValue();
  }

  public get userFeatures(): string[] {
    return this.features$.getValue();
  }

  isFeatureEnabled(feature: string): boolean {
    const features = this.userFeatures;
    return features.includes(feature);
  }

  getLDFlagValue<T = boolean>(flag: string): T {
    const flags = this.ldFlags$.getValue();
    if (!flags) {
      return null;
    }

    return flags[flag];
  }

  hasPermission(requiredPermission: string | string[], areAllPermissionsRequired = false): boolean {
    if (!requiredPermission) {
      return true;
    }

    const functionalPermissions = this.profile?.functionalPermissions || [];
    const userPermissions = this.profile?.userPermissions || [];
    const acquiredPermissions = [...functionalPermissions, ...userPermissions];

    const requiredPermissions = Array.isArray(requiredPermission) ? requiredPermission : [requiredPermission];

    if (!requiredPermission.length) {
      return true;
    }

    if (areAllPermissionsRequired) {
      return requiredPermissions.every((permission) => acquiredPermissions.includes(permission));
    }

    return requiredPermissions.some((permission) => acquiredPermissions.includes(permission));
  }
}
