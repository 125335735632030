/* eslint-disable no-console */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_TOKEN, Environment } from '@core/models/environment.model';
import { FEGatewayResponse } from '@core/models/frontend-gateway-response.model';
import { DEFECT } from '@shared/constants/request-url-types';
import { CreateDefect, CreateDefectResponse, Defect, UpdateDefect } from '@todo/model/defect.model';
import { Observable, of, zip } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface DefectQueryParams {
  // from `defectType.statusId`
  status?: number;
  getBookingInfo?: boolean;
  getBookingHeader?: boolean;
  requiresBooking?: boolean;
  equipmentReference?: string;
  equipmentGroupReference?: string;
  // ISO date string
  startDate?: string;
  // ISO date string
  endDate?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DefectsService {
  private requestUrl = `${this.env.serviceBaseServicePlanning}/${DEFECT.URL}`;
  constructor(@Inject(ENVIRONMENT_TOKEN) private env: Environment, private http: HttpClient) {}

  getCount(params: DefectQueryParams = {}): Observable<FEGatewayResponse<number>> {
    const queryParams = new HttpParams({ fromObject: params as any });
    return this.http.get<FEGatewayResponse<number>>(`${this.requestUrl}/count`, { params: queryParams });
  }

  getAll(params: DefectQueryParams = {}): Observable<FEGatewayResponse<Defect[]>> {
    const queryParams = new HttpParams({ fromObject: params as any });
    return this.http.get<FEGatewayResponse<Defect[]>>(`${this.requestUrl}`, { params: queryParams }).pipe(
      // backend throws 404 OK if no defect is found and we should handle it as 200 OK and return empty array
      catchError((error) => {
        if (error.status === 404 && error.statusText === 'OK') {
          return of({ data: [], isSuccesful: true } as FEGatewayResponse<Defect[]>);
        }
        throw error;
      })
    );
  }

  getOne(defectReference: string): Observable<FEGatewayResponse<Defect>> {
    if (!defectReference) throw new Error('no defect reference set');
    return this.http.get<FEGatewayResponse<Defect>>(`${this.requestUrl}/${defectReference}`);
  }

  getMultiple(defectReferences: string[]): Observable<FEGatewayResponse<Defect[]>> {
    const parallelGetOneRequests = defectReferences.map((defectReference) => this.getOne(defectReference));
    return zip(...parallelGetOneRequests).pipe(
      map((defectResponses: FEGatewayResponse<Defect>[]) => {
        const defects = defectResponses.map((x) => x.data);
        const response: FEGatewayResponse<Defect[]> = { data: defects, isSuccesful: true };
        return response;
      })
    );
  }

  createDefect(data: CreateDefect): Observable<FEGatewayResponse<CreateDefectResponse>> {
    const formData = new FormData();
    formData.append('defectData', JSON.stringify(data));
    return this.http.post<FEGatewayResponse<CreateDefectResponse>>(this.requestUrl, formData);
  }

  updateDefect(externalReference: string, data: UpdateDefect): Observable<FEGatewayResponse<CreateDefectResponse>> {
    const requestUrl = `${this.requestUrl}/${externalReference}`;
    return this.http.put<FEGatewayResponse<CreateDefectResponse>>(requestUrl, data);
  }

  getImageUrlFromPictureId(defectReference: string, pictureId: string | number) {
    return `${this.requestUrl}/${defectReference}/picture/${pictureId}`;
  }

  getPictureIdFromImageUrl(imageUrl: string) {
    if (!imageUrl) {
      return null;
    }
    // url can be of type https://.../v1/defects/:defectReference/picture/:pictureId?maxWidth=...&...
    const urlWithoutQueryParams = imageUrl.split('?')[0];
    const urlSegments = urlWithoutQueryParams.split('/');
    return +urlSegments.pop();
  }

  uploadDefectImages(defectReference: string, pictures: File[]) {
    const requestUrl = `${this.requestUrl}/${defectReference}/picture`;
    const formData = new FormData();
    pictures.forEach((image) => formData.append('images', image));
    return this.http.post<FEGatewayResponse<number[]>>(requestUrl, formData);
  }

  removeDefectImage(defectReference: string, pictureId: string | number) {
    const requestUrl = `${this.requestUrl}/${defectReference}/picture/${pictureId}`;
    return this.http.delete(requestUrl);
  }
}
