import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { QtyService } from '@shared/services/qty/qty.service';

@Pipe({
  name: 'qtyLength'
})
export class QtyLengthPipe implements PipeTransform {
  constructor(private translate: TranslateService, private decimalPipe: DecimalPipe, private qtyService: QtyService) {}

  transform(value: number, fraction?: any, translations?: any): string {
    const converted = this.qtyService.toTargetLengthUnit(value);
    const formatted = this.decimalPipe.transform(converted, fraction || QtyService.DIGITINFO_NO_FRACTION);
    const translationString = !!translations ? translations : this.qtyService.getDistanceTranslationKey();
    return this.translate.instant(translationString, formatted);
  }
}
