import { HttpBackend, HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_TOKEN, Environment } from '@core/models/environment.model';
import { UserProfile } from '@core/models/user-profile.model';
import { LaunchDarklyService } from '@core/services/launchdarkly/launchdarkly.service';
import { UserStoreService } from '@core/services/user-store/user-store.service';
import { KeycloakEvent, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, Observable, Subscription, firstValueFrom, from, interval, of, throwError } from 'rxjs';
import { map, mergeMap, retry, switchMap, tap } from 'rxjs/operators';

export enum AuthLogoutEventType {
  KEYCLOACK = 'KeycloakEventType.OnAuthLogout',
  USER = 'KeycloakEventType.OnUserTriggeredLogout'
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private static readonly TOKEN_REFRESH_TIME_INTERVAL_MS: number = 240000; // 4 minutes
  private static readonly FMP_AUTH_RETRY_COUNT: number = 3;

  private keycloakParsedToken: Keycloak.KeycloakTokenParsed | undefined = {};
  private rawHttpClient: HttpClient;

  /** token currently used for our services */
  public fmpToken = '';
  /** currently used just to fetch the fmpToken
   * but in the future will be used for our services */
  public fdGwToken = '';

  private userProfileSub = new Subscription();
  private tokenRefreshSub = new Subscription();

  private fdGwToken$: BehaviorSubject<string> = new BehaviorSubject(null);

  public getFdGwTokenAsObservable: Observable<string> = this.fdGwToken$.asObservable();

  constructor(
    httpBackend: HttpBackend,
    private httpClient: HttpClient,
    private keycloakService: KeycloakService,
    private launchDarklyService: LaunchDarklyService,
    private userService: UserStoreService,
    @Inject(ENVIRONMENT_TOKEN) private environment: Environment
  ) {
    this.rawHttpClient = new HttpClient(httpBackend);
  }

  /**
   * boolean flag indicating if Keycloak token is expired or not
   *
   * @returns boolean flag indicating if Keycloak token is expired or not
   * @description If keycloak is not initialized then this function will return false!
   */
  public isKeycloakTokenExpired(): boolean {
    if (this.keycloakParsedToken == null) {
      return false;
    }
    try {
      const tokenExpiryTimeEpoch = this.keycloakParsedToken.exp || -1;
      const expireInSeconds = Math.round(tokenExpiryTimeEpoch - new Date().getTime() / 1000);
      return expireInSeconds <= 0 ? true : false;
    } catch (error) {
      // failed to get token expiry
      return false;
    }
  }

  /** Handling different Keycloak events
   ** OnAuthError = 0,
   ** OnAuthLogout = 1,
   ** OnAuthRefreshError = 2,
   ** OnAuthRefreshSuccess = 3,
   ** OnAuthSuccess = 4,
   ** OnReady = 5,
   ** OnTokenExpired = 6
   */
  public handleKeycloakEvent(event: KeycloakEvent): void {
    /** ---------------------OnAuthSuccess------------------------ */
    if (event.type === KeycloakEventType.OnAuthSuccess) {
      // auth successful
      this.onSuccessfulKeycloakLogin();
    }

    /** ---------------------OnTokenExpired--------------------------- */
    if (event.type === KeycloakEventType.OnTokenExpired) {
      // token expired, needs to be refreshed
      this.refreshToken();
    }

    /** ---------------------.OnAuthLogout------------------------------ */
    if (event.type === KeycloakEventType.OnAuthLogout) {
      // logout triggered
      this.logout(AuthLogoutEventType.KEYCLOACK).subscribe();
    }

    /** ---------------------OnAuthError---------------------------------- */
    if (event.type === KeycloakEventType.OnAuthError) {
      // authentication error, logging out
      console.error('KeycloakEventType.OnAuthError', event.args);
    }

    /** ---------------------OnAuthRefreshError------------------------------ */
    if (event.type === KeycloakEventType.OnAuthRefreshError) {
      // authentication refresh error, logging out
      console.error('KeycloakEventType.OnAuthRefreshError', event.args);
    }
  }

  public getFdGwToken() {
    return this.fdGwToken;
  }

  /** Refresh the Keycloak token and create a new FMP token */
  public async refreshToken(): Promise<void> {
    if (!this.userService.isAuthenticated) {
      return;
    }
    try {
      this.keycloakParsedToken = this.keycloakService.getKeycloakInstance().tokenParsed;
      // -1 to force refresh the token
      await this.keycloakService.updateToken(-1);
      await firstValueFrom(this.createToken());

      // dispatch the new token to the navbar
      document.dispatchEvent(new CustomEvent('navbar_RefreshToken', { detail: this.fdGwToken }));
    } catch (err) {
      console.error(`Error in refreshing the token: ${err}`);
    }
  }

  /** logout the user*/
  public logout(eventType: AuthLogoutEventType): Observable<void> {
    // if the logout event is triggered by the user, clear local storage and session storage
    // no need to reset in-app stores because the user will be redirected back to myscania
    if (eventType === AuthLogoutEventType.USER) {
      window.sessionStorage.clear();
      window.localStorage.clear();
    }

    if (!this.keycloakParsedToken) {
      // cannot perform logout because there is no token
      return of(null);
    }

    return from(this.keycloakService.logout(this.environment.myScaniaURL));
  }

  /**  method invoked after successful keycloak login */
  private onSuccessfulKeycloakLogin(): void {
    this.keycloakParsedToken = this.keycloakService.getKeycloakInstance().tokenParsed;

    this.userProfileSub.unsubscribe();
    this.userProfileSub = this.createToken()
      .pipe(
        tap(() => this.scheduleTokenRefresh()),
        mergeMap(() => this.getFmpUserProfile()),
        mergeMap((userProfile) =>
          this.launchDarklyService.getFlags(userProfile).pipe(map((ldFlags) => ({ userProfile, ldFlags })))
        )
      )
      .subscribe({
        next: ({ ldFlags, userProfile }) => {
          this.userService.profile$.next(userProfile);
          this.userService.ldFlags$.next(ldFlags);
          this.userService.isAuthenticated$.next(true);
        },
        error: (error) => console.error('Failed to create FMP token. Reason: ' + JSON.stringify(error))
      });
  }

  /** create the Federated Gateway Token which will be used in the future
   * and the FMP Token which is currently used for our services */
  private createToken(): Observable<void> {
    // first get the federated gateway token
    return from(this.keycloakService.getToken()).pipe(
      // which we use to create the FMP token
      switchMap((fdGwToken) => {
        this.fdGwToken = fdGwToken;
        this.fdGwToken$.next(fdGwToken);

        if (!fdGwToken) {
          return throwError(() => new Error('invalid_fdgw_token'));
        }
        const headers = { authorization: `Bearer ${this.fdGwToken}`, 'x-client': this.environment.keycloak.clientId };
        return this.rawHttpClient.post<FmpTokenResponse>(this.environment.auth + 'token', null, { headers });
      }),
      // for which we retry x amount of times
      retry(AuthService.FMP_AUTH_RETRY_COUNT),
      map((response) => {
        this.fmpToken = response.access_token;
      })
    );
  }

  /** get the current user profile */
  private getFmpUserProfile(): Observable<UserProfile> {
    const profileUrl = `${this.environment.facadeBaseAws}/api/v1/profile`;

    return this.httpClient.get<UserProfile>(profileUrl);
  }

  /**
   * Schedule the Refresh token timer
   */
  private scheduleTokenRefresh(): void {
    this.tokenRefreshSub.unsubscribe();
    this.tokenRefreshSub = interval(AuthService.TOKEN_REFRESH_TIME_INTERVAL_MS).subscribe(() => this.refreshToken());
  }
}

export interface FmpTokenResponse {
  access_token: string;
}

/**  Logout event payload that is sent to Auth API */
export interface LogoutEventPayload {
  sessionState: string;
  clientId: string;
  reason: string;
}
