import { Component } from '@angular/core';

export interface MatSpinnerOptions {
  diameter?: number;
  /** Width of the spinner's stroke. */
  strokeWidth?: number;
}
@Component({
  selector: 'spinner-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  spinnerOptions: MatSpinnerOptions = { diameter: 30, strokeWidth: 3 };
}
