// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.information-banner-container {
  background-color: var(--sdds-blue-50);
  display: flex;
  margin-bottom: 2rem;
}
.information-banner-container .icon,
.information-banner-container .content {
  padding: 1.2rem;
}
.information-banner-container .icon h5,
.information-banner-container .content h5 {
  margin-bottom: 0;
}
.information-banner-container .icon {
  padding-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/information-banner/information-banner.component.scss"],"names":[],"mappings":"AAAA;EACE,qCAAA;EACA,aAAA;EACA,mBAAA;AACF;AACE;;EAEE,eAAA;AACJ;AACI;;EACE,gBAAA;AAEN;AAEE;EACE,gBAAA;AAAJ","sourcesContent":[".information-banner-container {\n  background-color: var(--sdds-blue-50);\n  display: flex;\n  margin-bottom: 2rem;\n\n  .icon,\n  .content {\n    padding: 1.2rem;\n\n    h5 {\n      margin-bottom: 0;\n    }\n  }\n\n  .icon {\n    padding-right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
