import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements OnInit {
  @Input() expanded: boolean;
  @Input() title: string;
  @Input() showGreyInput = false;
  showContent: boolean;

  constructor() {}

  ngOnInit(): void {
    this.showContent = this.expanded;
  }

  toggle() {
    this.showContent = !this.showContent;
  }
}
