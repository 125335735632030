import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const RELATIVE_TIME_I18N = {
  minute: 'Chs_UpdateM1', // minute ago
  minutes: 'Chs_UpdateM', // minutes ago
  hour: 'Chs_UpdateH1', // hour ago
  hours: 'Chs_UpdateH', // hours ago
  day: 'Chs_UpdateD1', // day ago
  days: 'Chs_UpdateD' // days ago
};

@Pipe({
  name: 'relativeTime'
})
export class RelativeTimePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: string): string {
    const date = dayjs(value);
    const diffInDays = dayjs().diff(dayjs(date), 'day');
    // Showing here days instead of month for now as we don't have translations for month
    // and until we know more if it's required
    // days equal to 26 or later considers month in dayjs relative time
    if (diffInDays >= 26) {
      return this.translate.instant(RELATIVE_TIME_I18N.days, diffInDays);
    }
    const [timeValue, unit] = date.fromNow(true).split(' ');
    const timeCount = +timeValue || 1; // As the relative time here returns 'an hour' as a string, so displaying 1 in this case
    return this.translate.instant(RELATIVE_TIME_I18N[unit], timeCount);
  }
}
