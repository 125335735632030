// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.number-card-container {
  background-color: var(--sdds-grey-50);
  padding: 2rem;
  border-left: 4px solid var(--sdds-blue-500);
  border-radius: 4px;
}
.number-card-container label {
  color: var(--sdds-grey-958);
  font-weight: bold;
}
.number-card-container .card-value {
  margin-top: 2rem;
  font-size: 4rem;
  font-weight: bold;
}
.number-card-container .icon-container {
  background: var(--sdds-grey-100);
  padding: 2rem;
  border-radius: 50%;
  margin-left: auto;
}
.number-card-container .icon-container img {
  width: 7rem;
  height: 7rem;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/number-card/number-card.component.scss"],"names":[],"mappings":"AAAA;EACE,qCAAA;EACA,aAAA;EACA,2CAAA;EACA,kBAAA;AACF;AACE;EACE,2BAAA;EACA,iBAAA;AACJ;AAEE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AAAJ;AAGE;EACE,gCAAA;EACA,aAAA;EACA,kBAAA;EACA,iBAAA;AADJ;AAGI;EACE,WAAA;EACA,YAAA;AADN","sourcesContent":[".number-card-container {\n  background-color: var(--sdds-grey-50);\n  padding: 2rem;\n  border-left: 4px solid var(--sdds-blue-500);\n  border-radius: 4px;\n\n  label {\n    color: var(--sdds-grey-958);\n    font-weight: bold;\n  }\n\n  .card-value {\n    margin-top: 2rem;\n    font-size: 4rem;\n    font-weight: bold;\n  }\n\n  .icon-container {\n    background: var(--sdds-grey-100);\n    padding: 2rem;\n    border-radius: 50%;\n    margin-left: auto;\n\n    img {\n      width: 7rem;\n      height: 7rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
