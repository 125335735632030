import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserStoreService } from '@core/services/user-store/user-store.service';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { filter, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthSsoGuard extends KeycloakAuthGuard {
  constructor(
    protected override router: Router,
    protected keycloakService: KeycloakService,
    private userService: UserStoreService
  ) {
    super(router, keycloakService);
  }

  isAccessAllowed(): Promise<boolean> {
    return this.isValidUser();
  }

  async isValidUser(): Promise<boolean> {
    if (!this.authenticated) {
      await this.keycloakAngular.login().catch((e) => console.error(e));
      return false;
    }
    return await firstValueFrom(this.userService.isAuthenticated$.pipe(filter((isAuthenticated) => !!isAuthenticated)));
  }
}
