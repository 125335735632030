import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { ERROR_MESSAGES } from '@shared/constants/error-messages';
import { Subscription } from 'rxjs';

@Component({
  selector: 'text-input',
  templateUrl: './text-input.component.html'
})
export class TextInputComponent implements ControlValueAccessor, AfterViewInit, OnInit, OnDestroy {
  ERROR_MESSAGES = ERROR_MESSAGES;

  @ViewChild('textInput', { static: true }) input: ElementRef;
  @Input() id: string;
  @Input() label: string;
  @Input() inputType = 'text';
  @Input() size = 'sm'; // sm | md
  @Input() placeholder = '';
  @Input() maxlength: number;
  @Input() tooltip: string;
  @Input() readonly = false;
  @Input() minValue: number;
  @Input() stepValue: number;
  /** value of type `any` like `TranslateDirective.translateParams` type */
  @Input() translateParams: { [validatorKey: string]: any } = {};
  @Input() showGreyInput = false;

  textInputControl: FormControl = new FormControl('');
  errorKeys: Array<string> = [];

  private sub: Subscription;

  onChange: (value: any) => void;
  onTouched: () => void;

  constructor(@Optional() @Self() public controlDir: NgControl) {
    if (controlDir) {
      controlDir.valueAccessor = this;
    }
  }

  writeValue(value: string): void {
    this.input.nativeElement.value = value || '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    if (this.controlDir) {
      this.textInputControl = this.controlDir.control as FormControl;
    }
    this.sub = this.textInputControl?.valueChanges.subscribe(() => this.updateInputValidity());
  }

  ngAfterViewInit(): void {
    const origFunction = this.textInputControl.markAsTouched;
    this.textInputControl.markAsTouched = () => {
      origFunction.apply(this.controlDir.control);
      this.updateInputValidity();
    };
  }

  updateInputValidity() {
    if (!this.textInputControl.errors) {
      this.errorKeys = [];
      return;
    }
    this.errorKeys = Object.keys(this.textInputControl.errors);
  }

  markAsTouched() {
    if (this.onTouched) {
      this.onTouched();
      this.updateInputValidity();
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
