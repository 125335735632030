import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

export type LocalizedDatePattern =
  | 'shortDate'
  | 'mediumDate'
  | 'mediumDateTime'
  | 'shortDateTime'
  | 'shortTime'
  | 'dayOfMonth'
  | string;
export type LocalizedDateInput = dayjs.ConfigType;

export const DATE_FORMATS = {
  mediumDate: 'DD/MM/YYYY',
  mediumDateTime: 'DD/MM/YYYY HH:mm',
  shortDate: 'MM/YYYY',
  ISO: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
  shortDateTime: 'DD/MM HH:mm',
  shortTime: 'HH:mm',
  dayOfMonth: 'D'
};

@Pipe({
  name: 'localizedDate'
})
export class LocalizedDatePipe implements PipeTransform {
  transform(value: LocalizedDateInput, pattern: LocalizedDatePattern = 'mediumDate'): string {
    // we want to format only valid dates and return empty strings on invalid ones
    // also have the check for nullish value because dayjs(undefined).isValid() returns true
    // but in this case we consider it an invalid date
    if (!value) {
      return '';
    }
    const date = dayjs(value);
    // invalid dates may come as "0001-01-01T00:00:00Z" which dayjs validates
    if (!date.isValid() || date.year() === 1) {
      return '';
    }
    return date.format(DATE_FORMATS[pattern] || pattern);
  }
}
