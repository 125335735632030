import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable()
export class PaginatorIntl {
  constructor(protected translate: TranslateService) {}

  /** A label for the button that decrements the current page. */
  get previousPageLabel() {
    return this.translate.instant('FöregåendeSida'); // Previous page
  }

  get itemsPerPageLabel() {
    return this.translate.instant('PosterPerSida'); // Previous page
  }
  /** A label for the button that increments the current page. */
  get nextPageLabel() {
    return this.translate.instant('NästaSida'); // Next page
  }

  changes: Subject<void> = new Subject();

  getRangeLabel: (page: number, pageSize: number, length: number) => string = (page, pageSize, length) => {
    const totalPages = Math.ceil(length / pageSize);
    const label = this.translate.instant('Av')?.toLowerCase(); // {} of {}
    // page is zero-based
    return `${page + 1} ${label} ${totalPages}`;
  };
}
