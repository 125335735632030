import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { S3UploadService } from '@checklist/services/s3-upload/s3-upload.service';
import { ModalContentComponent, ModalEventType } from '@shared/models/modal';
import { forkJoin } from 'rxjs';

export type UploadImagesOutput = {
  addedImages: { path: string; base64: string | ArrayBuffer }[];
  removedImages: string[];
};
@Component({
  selector: 'upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.css']
})
export class UploadImageComponent extends ModalContentComponent implements OnInit {
  addedImages: File[] = [];
  removedImages = [];

  @Input() selectedImage = ''; // selected image to display in image handler from checklistForm
  @Input() uploadedImages = [];

  @Output() imageUploaded: EventEmitter<UploadImagesOutput> = new EventEmitter();

  constructor(private uploadImageService: S3UploadService) {
    super();
  }

  ngOnInit() {
    this.setModalEventDisabled(ModalEventType.POSITIVE, true);
  }

  /**
   * hook to execute when the user clicks on save
   * to upload the selected image
   */
  override onPositiveAction(): boolean | Promise<boolean> {
    if (!this.addedImages.length && !!this.removedImages.length) {
      this.imageUploaded.emit({ addedImages: [], removedImages: this.removedImages });
      return true;
    }
    // disable the positive action so the user does not upload the same picture multiple times
    this.setModalEventDisabled(ModalEventType.POSITIVE, true);
    return new Promise((resolve) => {
      // using `.toPromise()` instead of creating a new promise does not work in this case
      // and this subscription does not represent a memory leak, as it only emits once
      const data = Array.from(this.addedImages).map((item) =>
        this.uploadImageService.uploadImage(item, { getBase64: true })
      );
      return forkJoin(data).subscribe({
        next: (value) => {
          this.imageUploaded.emit({ addedImages: value, removedImages: this.removedImages });
          resolve(true);
        },
        error: (e) => {
          console.error(e);
          // if the upload fails, we need to re-enable the positive action
          this.setModalEventDisabled(ModalEventType.POSITIVE, false);
          resolve(false);
        }
      });
    });
  }

  onAddedImagesChange(images: File[]) {
    this.addedImages = images;
    if (!!this.addedImages.length) {
      this.setModalEventDisabled(ModalEventType.POSITIVE, false);
    }
  }

  onImagesRemoved(images: string[]) {
    if (!images) {
      return null;
    }

    this.removedImages = images;
    this.setModalEventDisabled(ModalEventType.POSITIVE, false);
  }
}
