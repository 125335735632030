import { Injectable } from '@angular/core';
import { UserStoreService } from '@core/services/user-store/user-store.service';
import { Unit } from '@shared/constants/constants';
import Qty from 'js-quantities';

@Injectable({
  providedIn: 'root'
})
export class QtyService {
  // translation keys
  DISTANCE_KM = '_0_Km';
  DISTANCE_KM_PER_YEAR = '_0_Km_År';
  DISTANCE_MILES = '_0_Miles';
  DISTANCE_MILES_PER_YEAR = '_0_Miles_År';
  TIME_HOURS = '_0_H';
  TIME_HOURS_PER_YEAR = '_0_H_År';

  // https://angular.io/api/common/DecimalPipe#digitsinfo
  public static DIGITINFO_NO_FRACTION = '1.0-0';
  constructor(private userService: UserStoreService) {}

  /** convert a length value from base unit (meters) to target unit (km or miles) */
  toTargetLengthUnit(value: number): number {
    const convert = Qty.swiftConverter('m', this.getTargetLengthUnit());
    return convert(value);
  }

  /** convert a length value from target unit (km or miles) to base unit (meters) */
  toBaseLengthUnit(value: number): number {
    const convert = Qty.swiftConverter(this.getTargetLengthUnit(), 'm');
    return convert(value);
  }

  /** convert a time value from base unit (seconds) to target unit (hours) */
  toTargetTimeUnit(value: number): number {
    const convert = Qty.swiftConverter('s', this.getTargetTimeUnit());
    return convert(value);
  }

  /** convert a time value from target unit (hours) to base unit (seconds) */
  toBaseTimeUnit(value: number): number {
    const convert = Qty.swiftConverter(this.getTargetTimeUnit(), 's');
    return convert(value);
  }

  /** convert a size value from base unit (byte) to target unit (megabyte) */
  toTargetSizeUnit(value: number): number {
    const convert = Qty.swiftConverter('byte', this.getTargetSizeUnit());
    return convert(value);
  }

  /** convert a size value from target unit (megabyte) to base unit (byte) */
  toBaseSizeUnit(value: number) : number{
    const convert = Qty.swiftConverter(this.getTargetSizeUnit(), 'byte');
    return convert(value);
  }

  getDistanceTranslationKey() {
    return this.getTargetLengthUnit() === 'km' ? this.DISTANCE_KM : this.DISTANCE_MILES;
  }

  getAverageDistanceTranslationKey() {
    return this.getTargetLengthUnit() === 'km' ? this.DISTANCE_KM_PER_YEAR : this.DISTANCE_MILES_PER_YEAR;
  }

  getTimeTranslationKey() {
    return this.TIME_HOURS;
  }

  getAverageTimeTranslationKey() {
    return this.TIME_HOURS_PER_YEAR;
  }

  getTargetLengthUnit() {
    const unit = this.userService.profile.units || Unit.METRIC;
    return unit === Unit.METRIC ? 'km' : 'miles';
  }

  getTargetTimeUnit() {
    return 'h';
  }

  getTargetSizeUnit() {
    return 'megabyte';
  }
}
