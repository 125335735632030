import { CommonModule } from '@angular/common';
import { Component, input, OnDestroy, OnInit, output, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@myscania/translation-utils-angular';
import { HereMapModule } from '@shared/components/here-map/here-map.module';
import { HereMapLocation } from '@shared/components/here-map/here-map.service';
import { SearchComponent } from '@shared/components/search/search.component';
import { Dealer, DealerPhone, SERVICE_CODE_I18N_KEY } from '@todo/model/dealer.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'here-workshop-map',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, HereMapModule, SearchComponent, TranslateModule],
  templateUrl: './here-workshop-map.component.html',
  styleUrl: './here-workshop-map.component.scss'
})
export class HereWorkshopMapComponent implements OnInit, OnDestroy {
  locations = input<HereMapLocation<Dealer>[]>();
  workshopListLabel = input<string>();
  mapCenter: HereMapLocation<Dealer>;
  selectedWorkshop: Dealer;
  selectedWorkshopPhone: DealerPhone;

  locationSelected = output<Dealer>();

  isDetailsPanelVisible = false;
  private sub = new Subscription();
  searchWorkshop = new FormControl('');

  filteredLocations = signal<HereMapLocation<Dealer>[]>([]);

  ngOnInit() {
    this.filteredLocations.set(this.locations());
    this.sub.add(this.searchWorkshop.valueChanges.subscribe(() => this.filterWorkshop()));
  }

  getServiceI18Key(serviceCode: string): string {
    return SERVICE_CODE_I18N_KEY[serviceCode] || '';
  }

  onWorkshopSelected(dealer: Dealer) {
    this.selectedWorkshop = dealer;
    this.selectedWorkshopPhone = dealer.phones?.find((phone) => phone.type === 'phonegeneral');

    this.locationSelected.emit(dealer);
    this.isDetailsPanelVisible = true;
  }

  onListItemSelected(location: HereMapLocation<Dealer>) {
    this.mapCenter = location;
    this.onWorkshopSelected(location.data);
  }

  filterWorkshop(): void {
    const filterValue = this.searchWorkshop.value.toLowerCase();
    this.filteredLocations.set(
      this.locations().filter(
        (item) =>
          item.data.name.toLowerCase().includes(filterValue) ||
          item.data.address.street.toLowerCase().includes(filterValue) ||
          item.data.address.postalCode.toLowerCase().includes(filterValue) ||
          item.data.address.city.toLowerCase().includes(filterValue)
      )
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
