// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinner-container ::ng-deep .mat-progress-spinner circle,
.spinner-container .mat-spinner circle {
  stroke: var(--sdds-blue-400);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/loader/loader.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACE;;EAEE,4BAAA;AACJ","sourcesContent":[".spinner-container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  ::ng-deep .mat-progress-spinner circle,\n  .mat-spinner circle {\n    stroke: var(--sdds-blue-400);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
