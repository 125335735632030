import { AfterViewInit, Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ShepherdService } from 'angular-shepherd';
import { Subscription, delay, filter, of } from 'rxjs';

export interface StepOptions {
  id: string;
  text: string;
  title?: string;
  arrow?: boolean;
  cancelIcon?: {
    enabled: boolean;
  };
  attachTo?: {
    element: string | HTMLElement;
    on?: any;
  };
  classes: string;
  buttons: StepButton[];
}

interface StepButton {
  classes: string;
  primary: boolean;
  text: string;
  type: string;
}

export enum TOUR_GUIDE_POSITION {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left'
}

@Component({
  selector: 'guided-tour',
  templateUrl: './guided-tour.component.html',
  styleUrls: ['./guided-tour.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GuidedTourComponent implements AfterViewInit, OnDestroy {
  @Input() steps: StepOptions[];
  @Input() useModal = false;
  @Input() localStorageKey = '';
  @Input() cancelIcon = false; // will add to all modals
  @Input() scrollTo = false;

  headerTourStarted: boolean;
  tourSetup: boolean;

  setGuidedTourValue = 'True';
  sub = new Subscription();

  constructor(
    private router: Router,
    private shepherdService: ShepherdService
  ) {
    //  The guided tour in the nav bar header  also using this shepherdService
    //  so we don't want to show the module based tour untill unless the guided tour in header is cancelled or completed
    // while we are still getting the below events in case of e.g refresh
    ['navbarTourHeaderLoaded', 'navbarTourSidenavLoaded'].forEach((event) => {
      window.addEventListener(event, () => {
        this.headerTourStarted = true;
        if (this.tourSetup) {
          this.shepherdService.hide();
        }
      });
    });
    //  Will start module based tour only after the these events from nav bar guided tour emitted
    [
      'navbarHeaderTourCanceled',
      'navbarSidenavTourCompleted',
      'navbarSidenavTourCanceled',
      'navbarSidenavTourCancelIcon',
      'navbarHeaderTourCancelIcon'
    ].forEach((event) => {
      window.addEventListener(event, () => {
        this.headerTourStarted = false;
        if (this.tourSetup) {
          this.startTour();
        }
      });
    });
  }

  ngAfterViewInit() {
    if (!localStorage.getItem(this.localStorageKey)) {
      this.initTour();
    } else {
      if (this.shepherdService.isActive) {
        this.shepherdService.hide();
      }
    }

    // To hide tour in case of back button click of browser
    this.sub.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationStart && event.navigationTrigger === 'popstate'))
        .subscribe(() => {
          if (this.shepherdService.isActive) {
            this.shepherdService.hide();
          }
        })
    );
  }

  initTour() {
    this.shepherdService.defaultStepOptions = {
      classes: 'guided-tour',
      scrollTo: this.scrollTo,
      cancelIcon: {
        enabled: this.cancelIcon
      }
    };
    this.shepherdService.modal = this.useModal; // for backdrop greyed out
    this.shepherdService.confirmCancel = false; // this is native browser alert by the lib
    this.shepherdService.addSteps(this.steps);
    this.tourSetup = true;
    this.startTour();
    this.shepherdService.tourObject.on('cancel', () => {
      localStorage.setItem(this.localStorageKey, this.setGuidedTourValue);
      this.tourSetup = false;
    });
  }

  startTour() {
    this.sub = of(null)
      .pipe(delay(2000))
      .subscribe(() => {
        if (!this.headerTourStarted) {
          this.shepherdService.start();
        }
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
