import { Injectable } from '@angular/core';
import { MatDateFormats, NativeDateAdapter } from '@angular/material/core';
import { UserStoreService } from '@core/services/user-store/user-store.service';
import { DATE_FORMATS } from '@shared/pipes/localized-date/localized-date.pipe';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
// in order to parse a custom date format
// dayjs needs to be extended with the customParseFormat plugin
dayjs.extend(customParseFormat);

export const CUSTOM_MAT_FORMATS: MatDateFormats = {
  parse: {
    dateInput: DATE_FORMATS.mediumDate
  },
  display: {
    dateInput: DATE_FORMATS.mediumDate,
    monthYearLabel: DATE_FORMATS.shortDate,
    dateA11yLabel: 'LL',
    monthYearA11yLabel: DATE_FORMATS.shortDate
  }
};

@Injectable()
export class CustomMatDateAdapter extends NativeDateAdapter {
  constructor(private userService: UserStoreService) {
    super();
  }

  override format(date: Date, displayFormat: string): string {
    return dayjs(date).format(displayFormat);
  }

  override parse(value: string): Date | null {
    return dayjs(value, CUSTOM_MAT_FORMATS.parse.dateInput).toDate();
  }

  override getFirstDayOfWeek(): number {
    const startDayOfWeek = this.userService.profile?.customer?.startOfWeek;
    return startDayOfWeek === 'Monday' ? 1 : 0;
  }
}
