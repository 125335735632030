import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ENVIRONMENT_TOKEN, Environment } from '@core/models/environment.model';
import { Feature, LDFlag } from '@core/models/user-profile.model';
import { AnalyticsService } from '@core/services/analytics/analytics.service';
import { AuthLogoutEventType, AuthService } from '@core/services/auth/auth.service';
import { DatadogService } from '@core/services/datadog/datadog.service';
import { MopinionService } from '@core/services/mopinion/mopinion.service';
import { UserStoreService } from '@core/services/user-store/user-store.service';
import { TranslateService } from '@ngx-translate/core';
import { NavbarMenuItem, NavbarService } from '@shared/services/navbar/navbar.service';
import { UpdateService } from '@shared/services/update/update.service';
import { Subscription, fromEvent } from 'rxjs';
import { filter, mergeMap, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  isLoggedIn = false;
  environmentName = this.env.name;
  navbarMenuItems: NavbarMenuItem[] = [];
  canAccessZendeskWidget = false;
  fdGwToken: string; // sent to zendesk widget
  fdGwTokenSubscription: Subscription;

  fmpTokenDispatched = false;
  constructor(
    @Inject(ENVIRONMENT_TOKEN) private env: Environment,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private datadogService: DatadogService,
    private mopinion: MopinionService,
    private navbarService: NavbarService,
    private translate: TranslateService,
    private updateService: UpdateService,
    private userService: UserStoreService
  ) {}

  get cultureCode() {
    return this.translate.currentLang;
  }

  ngOnInit() {
    // start listening for new versions of the application
    this.updateService.listen();
    this.translate.setDefaultLang('en-GB');
    // handle application events
    fromEvent(window, 'navbarAccountLogOut')
      .pipe(mergeMap(() => this.authService.logout(AuthLogoutEventType.USER)))
      .subscribe();

    fromEvent(window, 'navbarLanguageSelected').subscribe((event: CustomEvent) =>
      this.translate.use(event.detail.culture)
    );

    this.userService.isAuthenticated$
      .pipe(
        filter((isAuthenticated) => !!isAuthenticated),
        tap(() => {
          this.isLoggedIn = true;
          this.translate.use(this.userService.profile.language);
          this.navbarMenuItems = this.navbarService.getMenuItems();
          this.mopinion.load(this.cultureCode);
          this.showZendeskWidget();
          this.analyticsService.init();
          this.datadogService.init();
          this.datadogService.setUserContext(this.userService.profile);
        }),
        switchMap(() => fromEvent(document, 'navbarHeaderReady')),
        filter((event: CustomEvent) => event.detail === true)
      )
      .subscribe(() => {
        // dispatch the new token to the navbar
        this.fmpTokenDispatched = true;
        document.dispatchEvent(new CustomEvent('navbar_UpdateToken', { detail: this.authService.fdGwToken }));
        document.dispatchEvent(new CustomEvent('navbar_UpdateFmpToken', { detail: this.authService.fmpToken }));
      });

    this.fdGwTokenSubscription = this.authService.getFdGwTokenAsObservable.subscribe(
      (fdGwToken) => (this.fdGwToken = fdGwToken)
    );
  }

  onCookiesConsentUpdated() {
    this.analyticsService.init();
    this.datadogService.init();
  }

  showZendeskWidget() {
    this.canAccessZendeskWidget = this.userService.getLDFlagValue(LDFlag.ZENDESK);
    // Feedback addded in Zendesk but feature toggle not available for users in prod right now,
    // so added this condition , mopinion can be removed completely once Zendesk available to all users
    if (!this.canAccessZendeskWidget) {
      setTimeout(() => {
        const surveyContent = document.getElementById('surveyContent');
        if (surveyContent) {
          surveyContent.style.display = 'block';
        }
      }, 2000);
    }
  }

  hasNewFeatures() {
    const isOverviewEnabled = this.userService.isFeatureEnabled(Feature.SP_OVERVIEW_PAGE_ACCESS);
    const isCalendarEnabled = this.userService.isFeatureEnabled(Feature.SP_CALENDAR_PAGE_ACCESS);
    return isOverviewEnabled || isCalendarEnabled;
  }

  goToPrivacyPage() {
    const myScaniaPrivacyUrl = `${this.env.myScaniaURL}/privacy`;
    window.open(myScaniaPrivacyUrl, '_blank');
  }

  ngOnDestroy() {
    this.fdGwTokenSubscription.unsubscribe();
  }
}
