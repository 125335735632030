import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_TOKEN, Environment } from '@core/models/environment.model';
import { AuthService } from '@core/services/auth/auth.service';
import { from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export enum AuthTokenType {
  /** legacy FMP token that most of the services still support */
  FMP,
  /** new MyScania token that services should gradually migrate to
   * and is currently used to create an FMP token */
  FG
}

/** keep the FMP token exchange functionality for now, but use the FG token by default
 * until we make sure all services are migrated to FG (MyScania) token */
export const AUTH_TOKEN_TYPE = new HttpContextToken(() => AuthTokenType.FG);

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    @Inject(ENVIRONMENT_TOKEN) private env: Environment,
    private authService: AuthService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.refreshTokenIfExpired().pipe(switchMap(() => next.handle(this.addHeader(req))));
  }

  private refreshTokenIfExpired(): Observable<void> {
    const expired = this.authService.isKeycloakTokenExpired();
    if (expired) {
      return from(this.authService.refreshToken());
    } else {
      return of(null);
    }
  }

  private addHeader(request: HttpRequest<any>): HttpRequest<any> {
    const tokenType = request.context.get(AUTH_TOKEN_TYPE);
    const token = tokenType === AuthTokenType.FMP ? this.authService.fmpToken : this.authService.fdGwToken;
    return request.clone({
      setHeaders: {
        authorization: `Bearer ${token}`,
        'x-client': this.env.xClientName
      }
    });
  }
}
