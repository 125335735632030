import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export const PAGINATOR_STORAGE_KEY = 'SP_PAGINATOR_CONFIG';

export interface PaginatorContextConfig {
  [key: string]: {
    pageSize: number;
  };
}

@Component({
  selector: 'paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnDestroy {
  @Input() showPageCount = true;
  @Input() pageSizeOptions: number[] = [5, 10, 20, 50, 100];
  @Input() defaultPageSize = 5;
  @Input() hidePageSize = false;
  @Input() paginatorContext: string;
  @Input() showFirstLastButtons = true;
  @Input() length: number;
  @Input() sddsInput = false;
  @Output() page: EventEmitter<PageEvent> = new EventEmitter();

  @ViewChild(MatPaginator) controller: MatPaginator;

  private eventSub: Subscription;
  constructor(private paginator: MatPaginatorIntl) {}
  /** angular material paginator has no go-to-page functionality
   * and we need to implement it */
  goToPage(pageIndex: number) {
    if (isNaN(pageIndex)) {
      return;
    }
    const previousPageIndex = this.controller.pageIndex;
    this.controller.pageIndex = pageIndex;
    this.controller.page.next({
      pageIndex,
      pageSize: this.controller.pageSize,
      length: this.controller.length,
      previousPageIndex
    });
  }

  /** save current page size in the localStorage for specified table context, if it exists */
  saveContextPageSize(pageSize: number) {
    // if there is no context specified, do nothing
    if (!this.paginatorContext) {
      return;
    }

    const localStorageData = localStorage.getItem(PAGINATOR_STORAGE_KEY);
    const paginatorConfig: PaginatorContextConfig = localStorageData ? JSON.parse(localStorageData) : {};

    paginatorConfig[this.paginatorContext] = Object.assign({}, paginatorConfig[this.paginatorContext], { pageSize });
    localStorage.setItem(PAGINATOR_STORAGE_KEY, JSON.stringify(paginatorConfig));
  }

  /** load the saved page size from the localStorage for specified table context, if it exists */
  loadContextPageSize() {
    if (!this.paginatorContext) {
      return;
    }

    const localStorageData = localStorage.getItem(PAGINATOR_STORAGE_KEY);
    const paginatorConfig: PaginatorContextConfig = localStorageData ? JSON.parse(localStorageData) : {};
    this.defaultPageSize = paginatorConfig[this.paginatorContext]?.pageSize || this.defaultPageSize;
  }

  ngOnInit(): void {
    this.loadContextPageSize();
    // listen for the pageSize change events
    this.eventSub = this.page
      .pipe(
        // if the current selected page is the same as the previous selected page
        // the only value left to change is the pageSize
        filter((event) => event.pageIndex === event.previousPageIndex),
        map((event) => event.pageSize)
      )
      .subscribe((pageSize) => this.saveContextPageSize(pageSize));
  }

  ngOnDestroy(): void {
    this.eventSub.unsubscribe();
  }
}
