// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-group input:focus {
  z-index: unset !important;
}
.search-group .input-icon {
  position: absolute;
  right: 1em;
  font-size: 20px;
  z-index: 10;
  top: 0.4em;
}
.search-group .input-icon.sm {
  font-size: 16px;
  right: 10px;
  top: calc(50% - 8px);
}
.search-group .input-icon .clear-icon {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/search/search.component.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;AAAJ;AAGE;EACE,kBAAA;EACA,UAAA;EACA,eAAA;EACA,WAAA;EACA,UAAA;AADJ;AAGI;EACE,eAAA;EACA,WAAA;EACA,oBAAA;AADN;AAII;EACE,eAAA;AAFN","sourcesContent":[".search-group {\n  input:focus {\n    z-index: unset !important;\n  }\n\n  .input-icon {\n    position: absolute;\n    right: 1em;\n    font-size: 20px;\n    z-index: 10;\n    top: 0.4em;\n\n    &.sm {\n      font-size: 16px;\n      right: 10px;\n      top: calc(50% - 8px);\n    }\n\n    .clear-icon {\n      cursor: pointer;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
