import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { Time } from '@shared/components/time-input/time-input.component';
import { DATE_FORMATS } from '@shared/pipes/localized-date/localized-date.pipe';
import { CUSTOM_MAT_FORMATS, CustomMatDateAdapter } from '@shared/utils/material-date-adapter';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';

export interface HighlightDate {
  className: string;
}

@Component({
  selector: 'datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: DatepickerComponent },
    { provide: DateAdapter, useClass: CustomMatDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_MAT_FORMATS }
  ]
})
export class DatepickerComponent implements ControlValueAccessor, OnChanges, OnInit, OnDestroy {
  DEFAULT_MIN_TIME: Time = { hours: 0, minutes: 0, seconds: 0 };
  minTime = this.DEFAULT_MIN_TIME;

  dateStringControl: FormControl<string>;
  dateControl: FormControl<Date>;
  timeStringControl: FormControl<string>;
  timeControl: FormControl<Time>;
  displayPicker = false;

  @Input() min: Date;
  @Input() max: Date;
  @Input() readonly = false;
  @Input() pickTime = false;
  // this is added to keep 2 versions for now , later will remove this and keep the single class name for all
  @Input() className = 'date-input';
  @Input() highlightDates = new Map<string, HighlightDate>();
  @Input() showGreyInput = false;
  private sub = new Subscription();

  onChange: (value: Date) => void;
  onTouched: () => void;

  constructor() {}

  @HostListener('click')
  onHostClick() {
    // if there's a click anywhere inside the datepicker component
    // mark the input as touched
    if (this.onTouched) {
      this.onTouched();
    }
  }

  /** check if two dates occur in the same day, regardless of time */
  areDatesOnSameDay(a: Date, b: Date) {
    const startOfA = dayjs(a).startOf('day');
    const startOfB = dayjs(b).startOf('day');

    return startOfA.diff(startOfB) === 0;
  }

  parseDate(dateString: string): Date {
    const format = this.pickTime ? CUSTOM_MAT_FORMATS.parse.dateTimeInput : CUSTOM_MAT_FORMATS.parse.dateInput;
    const parsedDate = dayjs(dateString, format);
    return parsedDate.toDate();
  }

  formatDate(date: Date): string {
    if (!date) {
      return '';
    }
    const format = this.pickTime ? CUSTOM_MAT_FORMATS.parse.dateTimeInput : CUSTOM_MAT_FORMATS.parse.dateInput;
    return dayjs(date).format(format);
  }

  setTime(date: Date, time: Time): Date {
    const timedDate = new Date(date);
    timedDate.setHours(time.hours, time.minutes, time.seconds || 0);

    return timedDate;
  }

  emitValue(value: Date) {
    if (this.onChange) {
      this.onChange(value);
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }

  writeValue(value: Date): void {
    this.dateStringControl.patchValue(this.formatDate(value), { emitEvent: false });
    this.dateControl.patchValue(value, { emitEvent: false });
    if (!this.pickTime) {
      return;
    }
    const time: Time = {
      hours: value?.getHours() || 0,
      minutes: value?.getMinutes() || 0
    };
    this.timeControl.patchValue(time, { emitEvent: false });
    this.updateMinTime(value);
  }

  registerOnChange(fn: (value: Date) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  togglePicker() {
    this.displayPicker = !this.displayPicker;
  }

  updateSelectedDate(date: Date) {
    this.dateControl.patchValue(date);
    if (!this.pickTime) {
      this.togglePicker();
    }
  }

  getCurrentDateTime() {
    const todaysDate = new Date();
    const h = todaysDate.getHours();
    const m = todaysDate.getMinutes();
    const s = todaysDate.getSeconds();

    return { currentHours: h, currentMinutes: m, currentSeconds: s };
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if min datetime value changes while we have a value
    // reset the current value to min date if lower
    if (changes['min']?.currentValue) {
      const minDate = changes['min'].currentValue as Date;

      if (minDate >= this.dateControl?.value) {
        this.dateControl.setValue(minDate);
      }

      this.updateMinTime(this.dateControl?.value);
    }
  }

  /** set current timestamp to a date */
  setCurrentTime(date: Date): Date {
    const { currentHours, currentMinutes, currentSeconds } = this.getCurrentDateTime();
    return this.setTime(date, {
      hours: currentHours,
      minutes: currentMinutes,
      seconds: currentSeconds
    });
  }

  /** check if there is a min time specified
   * in which case we need to reset the current selected time to min if it's lower */
  updateMinTime(selectedDate: Date) {
    if (!this.min) {
      return;
    }

    if (!this.areDatesOnSameDay(this.min, selectedDate)) {
      this.minTime = this.DEFAULT_MIN_TIME;
      return;
    }

    // selected date is on the same date as min date, so min time should be specified min time, not 00:00
    this.minTime = {
      hours: this.min.getHours(),
      minutes: this.min.getMinutes(),
      seconds: this.min.getSeconds()
    };

    const isTimedDateBeforeMin = dayjs(selectedDate).isBefore(this.min);
    if (isTimedDateBeforeMin) {
      this.timeControl.setValue(this.minTime);
    }
  }

  /** decorate date with the picked time if time picking is enabled, minding the min date, if specified */
  decorateDateWithPickedTime(date: Date): Date {
    let timedDate = new Date(date);
    if (this.pickTime) {
      timedDate = this.setTime(timedDate, this.timeControl.value);
      this.updateMinTime(timedDate);
    }
    return timedDate;
  }

  ngOnInit(): void {
    // date and time controls work with real data (Date and Time objects)
    // dateString and timeString controls work with the string representations
    // additional effort to keep the real value and it's string representation in sync is needed
    // because of the lack of time picking support from angular material
    this.dateControl = new FormControl(this.min || new Date());
    this.dateStringControl = new FormControl('');

    const hours = this.dateControl.value.getHours();
    const minutes = this.dateControl.value.getMinutes();
    const seconds = this.dateControl.value.getSeconds();
    this.timeControl = new FormControl({ hours, minutes, seconds });
    this.timeStringControl = new FormControl('');

    this.sub.add(
      this.dateStringControl.valueChanges.subscribe((value) => {
        const parsedDate = this.parseDate(value);

        // if the date is invalid, e.g. the user just cleared the date input
        // emit null through the form control
        if (isNaN(parsedDate.getTime())) {
          this.emitValue(null);
          return;
        }
        let timedDate = this.setCurrentTime(parsedDate);

        this.dateControl.patchValue(timedDate, { emitEvent: false });
        timedDate = this.decorateDateWithPickedTime(timedDate);

        if (this.pickTime) {
          const time: Time = {
            hours: parsedDate.getHours(),
            minutes: parsedDate.getMinutes(),
            seconds: parsedDate.getSeconds()
          };

          this.timeControl.patchValue(time, { emitEvent: false });
        }

        this.emitValue(timedDate);
      })
    );
    this.sub.add(
      this.dateControl.valueChanges.subscribe((value) => {
        let timedDate = this.setCurrentTime(value);

        timedDate = this.decorateDateWithPickedTime(timedDate);
        this.dateStringControl.patchValue(this.formatDate(timedDate), { emitEvent: false });

        this.emitValue(timedDate);
      })
    );

    this.sub.add(
      this.timeControl.valueChanges.subscribe((time: Time) => {
        const timedDate = this.setTime(this.dateControl.value, time);
        this.dateStringControl.patchValue(this.formatDate(timedDate), { emitEvent: false });
        this.emitValue(timedDate);
      })
    );
  }

  highlightDateClass = (inputDate: Date): string => {
    const formattedDate = dayjs(inputDate).format(DATE_FORMATS.mediumDate);
    const className = this.highlightDates.get(formattedDate)?.className;
    return className;
  };

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
