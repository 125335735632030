import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { UserStoreService } from '@core/services/user-store/user-store.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RolesGuard implements CanActivate {
  constructor(protected userService: UserStoreService, protected router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const requiredFeatureToggle = route.data['requiredFeature'];
    // wait for the user to be authenticated
    return this.userService.isAuthenticated$.pipe(
      filter((isAuthenticated) => isAuthenticated),
      map(() => {
        const hasFeatureToggle = requiredFeatureToggle
          ? this.userService.isFeatureEnabled(requiredFeatureToggle)
          : true;
        if (!hasFeatureToggle) {
          return this.router.createUrlTree(['/']);
        }
        return true;
      })
    );
  }
}
