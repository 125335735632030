import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { NoNetworkService } from '../services/no-network/no-network.service';
import { ToastrMessageService } from '../services/toastr-message/toastr-message.service';
import { AnalyticsService } from '@core/services/analytics/analytics.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(
    @Inject(Injector) private readonly injector: Injector,
    private analyticsService: AnalyticsService,
    private translate: TranslateService,
    private noNetworkService: NoNetworkService
  ) {
    super();
  }

  override handleError(error: Error) {
    // some API's like the totalodometer or totalruntime return 404 OK
    // which is not really an error, it just means that there is no data for specified resource
    // so we need to skip handling this type of error as 'Something went wrong'
    if (error instanceof HttpErrorResponse && error.statusText === 'OK' && error.status === 404) {
      return super.handleError(error);
    }

    // An error occurred. Please wait for a while and try again.
    let message = this.translate.instant('EttTillfälligtFelUppstod_VäntaEnStundOchFörsökIgen_OmProblemet');

    if (!this.noNetworkService.isNetworkAvailable()) {
      message = this.translate.instant('DuÄrOffline');
      this.toastr.warning(message);
    } else {
      this.toastr.error(message);
    }

    super.handleError(error);

    // as discussed with analytics team, we can push error messages to `adobeDataLayer`
    // and use it as a client-side error logger
    this.analyticsService.pushConsoleErrorEvent(error);
  }

  private get toastr(): ToastrMessageService {
    return this.injector.get(ToastrMessageService);
  }
}
