/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface ChecklistInputItem {
  value: any;
  label?: string;
}

@Component({
  // TODO: change this selector to `checklist-input`
  selector: 'app-checklist-input',
  templateUrl: './checklist-input.component.html',
  styleUrls: ['./checklist-input.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: ChecklistInputComponent }]
})
export class ChecklistInputComponent implements ControlValueAccessor {
  @Input() items: ChecklistInputItem[] = [];
  public values: any[] = [];
  public disabled = false;
  private itemsCaching: { [value: string]: boolean } = {};
  private onChange: (value: string[]) => void;
  private onTouched: () => void;
  constructor() {}

  writeValue(values: any[]): void {
    this.values = values;
    this.initCaching(this.values);
  }

  registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private emitValues(values: any[]): void {
    if (this.onChange) {
      this.onChange(values);
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }

  private initCaching(values: any[]): void {
    this.itemsCaching = {};
    for (const item of values) {
      this.itemsCaching[item] = true;
    }
  }

  isItemSelected(value: any): boolean {
    return this.itemsCaching[value] || false;
  }

  toggleItem(item: any): void {
    if (this.itemsCaching[item]) {
      this.itemsCaching[item] = false;
      // remove item from selected values if switched to false
      this.values = this.values.filter((other) => other !== item);
    } else {
      this.itemsCaching[item] = true;
      // add item to selected values if switched to true;
      this.values.push(item);
    }

    this.emitValues(this.values);
  }
}
