import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: CheckboxComponent }],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CheckboxComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() label: string;

  control: FormControl = new FormControl(false);
  sub: Subscription;

  onChange: (value: boolean) => void;
  onTouched: () => void;

  writeValue(value: boolean): void {
    this.control.patchValue(value, { emitEvent: false });
  }

  registerOnChange(onChange: (value: boolean) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
  }

  emit(value: boolean) {
    if (this.onChange) {
      this.onChange(value);
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }

  toggle() {
    if (!this.control.disabled) {
      this.control.patchValue(!this.control.value);
    }
  }

  ngOnInit() {
    this.sub = this.control.valueChanges.subscribe((value) => this.emit(value));
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
