import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { StepConfig, STEPPER_SUBMIT_BTN_LOADER } from '@shared/models/stepper.model';
import { ButtonType } from '@teamsp/components/button';

@Directive({
  selector: '[stepHeaderDef]'
})
export class StepperHeaderDefDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: '[stepLabelDef]'
})
export class StepperLabelDefDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: '[stepDef]'
})
export class StepperDefDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Component({
  selector: 'stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements AfterViewChecked {
  STEPPER_SUBMIT_BTN_LOADER = STEPPER_SUBMIT_BTN_LOADER;

  @Input() stepConfig: StepConfig;

  @ContentChild(StepperHeaderDefDirective) headerDef: StepperHeaderDefDirective;
  @ContentChild(StepperLabelDefDirective) headerLabelDef: StepperLabelDefDirective;
  @ContentChild(StepperDefDirective) contentDef: StepperDefDirective;
  @ViewChild('stepper') stepper: MatStepper;
  @Output() stepNext = new EventEmitter();

  ButtonType = ButtonType;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  stepBack() {
    this.stepper.previous();
  }

  stepForward() {
    this.stepNext.emit();
    if (!this.stepNext.observed) {
      this.moveToNextStep();
    }
  }

  moveToNextStep() {
    this.stepper.selected.completed = true;
    this.stepper.next();
  }
}
