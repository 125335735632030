import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ServicePlanningRouteRedirectGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): UrlTree {
    const segments = route.firstChild.url;
    const paths = segments.length ? segments.map((segment) => segment.path) : ['/'];
    return this.router.createUrlTree(paths);
  }
}
