import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import duration, { DurationUnitType } from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(duration);
dayjs.extend(relativeTime);

export type LocalizedDurationInput = number;
@Pipe({
  name: 'localizedDuration'
})
export class LocalizedDurationPipe implements PipeTransform {
  transform(value: LocalizedDurationInput, inputUnit?: DurationUnitType): string;
  transform(value: LocalizedDurationInput, inputUnit: DurationUnitType, outputUnit: DurationUnitType): number;
  transform(
    value: LocalizedDurationInput,
    inputUnit: DurationUnitType = 'seconds',
    outputUnit?: DurationUnitType
  ): number | string {
    const d = dayjs.duration(value, inputUnit);
    switch (outputUnit) {
      case 'minute':
      case 'minutes':
      case 'm':
        return d.asMinutes();
      case 'second':
      case 'seconds':
      case 's':
        return d.asSeconds();
      case 'hour':
      case 'hours':
      case 'h':
        return d.asHours();
      default:
        return d.format('mm:ss');
    }
  }
}
