import { Environment } from '@core/models/environment.model';
import { AuthService } from '@core/services/auth/auth.service';
import { KeycloakEvent, KeycloakService } from 'keycloak-angular';

declare global {
  interface Window {
    signOut: any;
    keycloak: any;
    config: any;
  }
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function initializeKeycloak(keycloakService: KeycloakService, authService: AuthService, env: Environment): () => Promise<any> {
  keycloakService.keycloakEvents$.subscribe((event: KeycloakEvent) => {
    authService.handleKeycloakEvent(event);
  });
  return (): Promise<any> =>
    new Promise(async (resolve, reject) => {
      try {
        await keycloakService.init({
          config: {
            url: env.keycloak.issuer,
            realm: env.keycloak.realm,
            clientId: env.keycloak.clientId
          },
          bearerExcludedUrls: ['/assets'],
          initOptions: {
            enableLogging: false,
            onLoad: 'check-sso',
            pkceMethod: 'S256',
            silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
          }
        });
        window.signOut = keycloakService;
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
}
