import { Component, inject, input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'number-card',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './number-card.component.html',
  styleUrl: './number-card.component.scss'
})
export class NumberCardComponent implements OnInit {
  label = input.required<string>();
  value = input.required<number>();
  icon = input<string>();
  iconUrl: SafeResourceUrl;

  private sanitizer = inject(DomSanitizer);

  ngOnInit() {
    if (this.icon()) {
      this.iconUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.icon());
    }
  }
}
