import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AGREEMENT,
  BOOKING,
  DEALER_SERVICE,
  DEFECT,
  EQUIPMENT_SETTINGS,
  MAINTENANCE_PLAN,
  NOTIFICATION_V2,
  ODOMETER,
  RUNTIME,
  SERVICE_ACTIVITY,
  WORKSHOP,
  WORKSHOP_HISTORY
} from '@shared/constants/request-url-types';
import { AgreementRequestMapper } from '@shared/mappers/agreement.mapper';
import { BookingRequestMapper } from '@shared/mappers/booking.mapper';
import { DealersRequestMapper } from '@shared/mappers/dealers.mapper';
import { DefectsRequestMapper } from '@shared/mappers/defects.mapper';
import { EquipmentSettingsRequestMapper } from '@shared/mappers/equipment-settings.mapper';
import { MaintenanceActivityRequestMapper } from '@shared/mappers/maintenance-activity.mapper';
import { RequestMapper } from '@shared/mappers/mapper';
import { NotificationRequestMapper } from '@shared/mappers/notification.mapper';
import { OdometerRequestMapper } from '@shared/mappers/odometer.mapper';
import { RuntimeRequestMapper } from '@shared/mappers/runtime.mapper';
import { ServiceActivityRequestMapper } from '@shared/mappers/service-activity.mapper';
import { WorkshopHistoryRequestMapper } from '@shared/mappers/workshop-history.mapper';
import { WorkshopRequestMapper } from '@shared/mappers/workshop.mapper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class RequestMapInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const mapper = this.getMapper(req);
    const mappedRequest = mapper.mapRequest(req);
    return next
      .handle(mappedRequest)
      .pipe(map((event) => (event instanceof HttpResponse ? mapper.mapResponse(event) : event)));
  }

  getMapper(req: HttpRequest<any>): RequestMapper {
    if (req.url.includes(AGREEMENT.URL)) {
      return new AgreementRequestMapper();
    } else if (req.url.includes(BOOKING.URL)) {
      return new BookingRequestMapper();
    } else if (req.url.includes(DEFECT.URL)) {
      return new DefectsRequestMapper();
    } else if (req.url.includes(DEALER_SERVICE.URL)) {
      return new DealersRequestMapper();
    } else if (req.url.includes(EQUIPMENT_SETTINGS.URL)) {
      return new EquipmentSettingsRequestMapper();
    } else if (req.url.includes(MAINTENANCE_PLAN.URL)) {
      return new MaintenanceActivityRequestMapper();
    } else if (req.url.includes(NOTIFICATION_V2.URL)) {
      return new NotificationRequestMapper();
    } else if (req.url.includes(ODOMETER.URL)) {
      return new OdometerRequestMapper();
    } else if (req.url.includes(RUNTIME.URL)) {
      return new RuntimeRequestMapper();
    } else if (req.url.includes(SERVICE_ACTIVITY.URL)) {
      return new ServiceActivityRequestMapper();
    } else if (req.url.includes(WORKSHOP_HISTORY.URL)) {
      return new WorkshopHistoryRequestMapper();
    } else if (req.url.includes(WORKSHOP.URL)) {
      return new WorkshopRequestMapper();
    }
    return new RequestMapper();
  }
}
