import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

const NUMBER_PATTERN = /\0|(([0-9])([0-9])*)/;

const intervalValidator =
  (min?: number, max?: number): ValidatorFn =>
  (control): { min?: boolean; max?: boolean } => {
    const value: number = +control.value;
    if (!isNaN(min) && !(value >= min)) {
      return { min: true };
    }
    if (max && value > max) {
      return { max: true };
    }
  };

@Component({
  selector: 'number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: NumberInputComponent }]
})
export class NumberInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() id: string;
  @Input() min: number;
  @Input() max: number;
  @Input() formatter: (value: number) => string = (value: number) => value.toString();
  @Input() parser: (value: string) => number = (value: string) => +value;
  @Input() bgColor = 'sdds-bg-white';

  numberControl: FormControl;

  valueChangeSub: Subscription;

  onChange: (value: number) => void;
  onTouched: () => void;
  constructor() {}

  private emitValue(value: number): void {
    if (this.onChange) {
      this.onChange(value);
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }

  writeValue(value: number): void {
    const formattedValue = this.formatValue(value);
    // convert incoming number values to strings
    // as the number input is actually of type text
    this.numberControl.setValue(formattedValue, { emitEvent: false });
  }

  registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.numberControl.disable();
    } else {
      this.numberControl.enable();
    }
  }

  ngOnInit(): void {
    const defaultValue = this.min ? this.min + '' : '';
    this.numberControl = new FormControl(defaultValue, [
      Validators.pattern(NUMBER_PATTERN),
      intervalValidator(this.min, this.max)
    ]);

    this.valueChangeSub = this.numberControl.valueChanges.subscribe((stringValue) => {
      const parsedValue = this.parseValue(stringValue);
      this.emitValue(parsedValue);
      const formattedValue = parsedValue && this.formatValue(parsedValue);
      if (formattedValue && formattedValue !== stringValue) {
        this.numberControl.setValue(formattedValue, { emitEvent: false });
      }
    });
  }

  ngOnDestroy(): void {
    this.valueChangeSub.unsubscribe();
  }

  up(): void {
    const errors = this.numberControl.errors;
    if (errors && (errors['max'] || errors['pattern'])) {
      return;
    }
    const value = this.parseValue(this.numberControl.value);
    if (!isNaN(this.max) && value >= this.max) {
      return;
    }
    const formattedValue = this.formatValue(value + 1);
    this.numberControl.setValue(formattedValue);
  }

  down(): void {
    const errors = this.numberControl.errors;
    if (errors && (errors['min'] || errors['pattern'])) {
      return;
    }
    const value = this.parseValue(this.numberControl.value);
    if (!isNaN(this.min) && value <= this.min) {
      return;
    }
    const formattedValue = this.formatValue(value - 1);
    this.numberControl.setValue(formattedValue);
  }

  formatValue(value: number): string {
    return this.formatter ? this.formatter(value) : value.toString();
  }

  parseValue(value: string): number {
    return this.parser ? this.parser(value) : +value;
  }
}
