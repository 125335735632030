import { Injector, Type } from '@angular/core';
import { Observable } from 'rxjs';

export class ModalContentComponent<RType = void, EName extends ModalEventName = ModalEventName> {
  /** Prevent a modal event from happening
   *
   * @example
   * this.setModalEventDisabled(ModalEventType.POSITIVE, true);
   * // ...
   * // some async operation that needs to complete before the modal can be closed
   * // ...
   * this.setModalEventDisabled(ModalEventType.POSITIVE, false);
   */
  setModalEventDisabled: (eventType: ModalEventType, disabled: boolean) => void;

  /** trigger a modal event from inside the modal content component
   *
   * @example
   * submitButtonPress() {
   *   this.modalAction(ModalEventType.POSITIVE);
   * }
   *
   * @example
   * submitButtonPressWithData() {
   *   this.modalAction(ModalEventType.POSITIVE, this.formData);
   * }
   *
   * @example
   * submitNamedButtonPressWithData() {
   *   this.modalAction(ModalEventType.POSITIVE, BookingModalEventName.CREATE, this.formData);
   * }
   */
  modalAction(eventType: ModalEventType, eventName: EName, data?: RType): Promise<void>;
  modalAction(eventType: ModalEventType, data: RType): Promise<void>;
  modalAction(eventType: ModalEventType): Promise<void>;
  modalAction(_eventType: ModalEventType, _eventNameOrData?: EName | RType, _data?: RType) {
    return Promise.resolve();
  }

  /** Should return the value that needs to be emitted back to the modal's parent component.
   *
   * Instead of the modal parent component manually accessing content component instance properties
   * ```
   * const modalInstance = this.modalService.open(...);
   * modalInstance.close.subscribe(({eventType}) => {
   *    doSomethingWithTheResult(modalInstance.componentInstance.formGroup.value)
   * })
   *```
   * the `valueAccessor` method can be implemented
   * ```
   * valueAccessor() {
   *   return this.formGroup.value;
   * }
   * ```
   * and the parent component can access the data from the modal resolution object
   * ```
   * modalInstance.close.subscribe(({eventType, data}) => {
   *   doSomethingWithTheResult(data);
   * })
   * ```
   */
  valueAccessor: () => RType = () => null;

  addModalAction(eventType: ModalEventType, buttonConfig: ModalButton<EName>): void;
  addModalAction(eventType: ModalEventType, label: string, icon?: string): void;
  addModalAction(_eventType: ModalEventType, _labelOrConfig: string | ModalButton<EName>, _icon?: string): void {
    return null;
  }

  removeModalAction: (eventType: ModalEventType) => void;

  /** if implementations for these hooks exist
   * they will be called right before closing and emitting their corresponding closing event.
   * If they return false or promise that resolves to false
   * the modal will not be closed and the event will not be emitted */
  onPositiveAction(_eventName?: EName): boolean | Promise<boolean> {
    return true;
  }
  onNegativeAction(_eventName?: EName): boolean | Promise<boolean> {
    return true;
  }
  onNeutralAction(_eventName?: EName): boolean | Promise<boolean> {
    return true;
  }
  onDismissAction(_eventName?: EName): boolean | Promise<boolean> {
    return true;
  }
}

export enum ModalEventType {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  NEUTRAL = 'neutral',
  DISMISS = 'dismiss'
}

/** base type for custom event name enums */
export type ModalEventName = string | number;

export interface ModalResolution<RType, EName> {
  eventType: ModalEventType;
  eventName?: EName;
  data?: RType;
}

export interface ModalButton<EName extends ModalEventName = ModalEventName> {
  text: string;
  containerClass?: string;
  eventName?: EName;
  icon?: string;
}

export interface ModalConfig<
  CType extends ModalContentComponent<RType, EName> = any,
  RType = void,
  EName extends ModalEventName = ModalEventName
> {
  title?: string;
  contentText?: string;
  contentComponent?: Type<CType>;
  contentComponentInputs?: Partial<CType>;
  dismissible?: boolean;
  injector?: Injector;
  popoverStyle?: 'modal' | 'drawer-fill' | 'drawer';
  size?: 'md' | 'sm' | 'lg' | 'xl';
  buttons?: {
    positive?: ModalButton<EName>;
    negative?: ModalButton<EName>;
    neutral?: ModalButton<EName>;
  };
}

export const DEFAULT_MODAL_CONFIG: ModalConfig = {
  dismissible: true,
  size: 'md',
  popoverStyle: 'modal'
};

export interface ModalInstance<CType extends ModalContentComponent<RType, EName>, RType, EName extends ModalEventName> {
  close: Observable<ModalResolution<RType, EName>>;
  contentComponentInstance?: CType;
  contentComponentInit?: Observable<CType>;
}
