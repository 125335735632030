import { AbstractControl } from '@angular/forms';
import { StepperOrientation } from '@angular/material/stepper';
import { ButtonComponentOutput } from '@teamsp/components/button';

export const STEPPER_SUBMIT_BTN_LOADER = 'stepper_submit_button_loader_context';

export interface StepConfig {
  steps: Step[];
  selectedStep?: number;
  orientation?: StepperOrientation;
  isLinear?: boolean;
  buttons?: {
    positive?: StepperButton;
    neutral?: StepperButton;
    dismiss?: StepperButton;
    submit?: StepperButton;
  };
}

export interface Step {
  label: string;
  stepControlForm?: AbstractControl;
  editable?: boolean;
  completed?: boolean;
}

interface StepperButton {
  title: string;
  action?: (output: ButtonComponentOutput) => void;
}

export enum StepperEventType {
  POSITIVE = 'positive',
  NEUTRAL = 'neutral',
  DISMISS = 'dismiss',
  SUBMIT = 'submit'
}
