import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GeneralAnalyticsEvent } from '@core/services/analytics/analytics';
import { AnalyticsProvider } from '@core/services/analytics/analytics.component';
import { AnalyticsEvent } from '@core/services/analytics/analytics.decorator';
import { PaginatorIntl } from '@shared/components/paginator/paginator-intl.service';
import { PaginatorComponent } from '@shared/components/paginator/paginator.component';
import { getBaseUrlFromRouteSnapshot } from '@shared/components/tabular-view/tabular-view.model';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface ListTableHeader {
  fieldName: string;
  title: string;
  /** each column is by default unfilterable */
  filterable?: boolean;
  /** each column is by default sortable */
  sortable?: boolean;
  /** specify this property if you want the data to be sorted by this column by default */
  defaultSort?: 'asc' | 'desc';
  /** if this value accessor is specified, row items will be sorted and filtered (if applicable)
   * based on the value returned by this value accessor */
  valueAccessor?: (row: any) => string;
}

@Component({
  selector: 'list-table',
  templateUrl: './list-table.component.html',
  styleUrls: ['./list-table.component.scss'],
  providers: [{ provide: MatPaginatorIntl, useClass: PaginatorIntl }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListTableComponent extends AnalyticsProvider implements OnInit, OnChanges, OnDestroy {
  PAGINATOR_CONTEXT = 'sp_paginator_list_table_context';

  @Input() data: any[] = [];
  @Input() headers: ListTableHeader[] = [];
  @Input() filter = '';
  @Input() keyAccessor: (row: any) => string = () => '';
  @Input() isRowSelectable = true;
  @Input() defaultSelection = true;
  @Input() selectFirstItemOnFilter = true;
  @Output() rowSelected = new EventEmitter();
  @Output() filteredData = new EventEmitter<any>();

  @ViewChild(PaginatorComponent, { static: true }) paginator: PaginatorComponent;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ContentChild(TemplateRef, { static: false }) rowDefTemplate: TemplateRef<any>;

  // table heads caching for performance reasons
  tableHeadersMap: Record<string, ListTableHeader> = {};
  displayedHeaders: string[] = [];
  defaultSortColumn: string = null;
  defaultSortDirection: SortDirection = null;
  hasFilterableFields = false;

  // base url to compare when going back
  baseUrl: string;

  selectedRowKey: string = null;
  dataSource: MatTableDataSource<any>;

  private sub: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    super();
  }

  isRowSelected(row: any) {
    return this.keyAccessor(row) === this.selectedRowKey;
  }

  getItemIndex(dataSource: any[], itemKey: any): number {
    return dataSource.findIndex((other) => this.keyAccessor(other) === itemKey);
  }

  selectRow(row: any) {
    if (!this.dataSource.data.length) {
      return this.rowSelected.emit(null);
    }
    const data = this.dataSource._orderData(this.dataSource.filteredData);

    // if the argument for this method is null then we need to select the first item in the table
    const rowToBeSelected = this.defaultSelection ? row || data[0] : row;

    if (!rowToBeSelected) {
      return this.rowSelected.emit(null);
    }

    // do nothing if we are already on the specified item
    const itemKey = this.keyAccessor(rowToBeSelected);
    if (itemKey === this.selectedRowKey) {
      return;
    }

    const itemIndex = this.getItemIndex(data, itemKey) + 1;
    // go to the page of the selected item
    // paginator needs to be initialized before accessing the pageSize property
    // this observable emits only once, no need to unsubscribe
    this.paginator.controller.initialized.subscribe(() => {
      // paginator page index is zero-based
      const page = Math.ceil(itemIndex / this.paginator.controller.pageSize) - 1;
      this.paginator.goToPage(page);
    });

    // navigate to route of the selected item and mark it as selected
    if (itemKey && this.defaultSelection) {
      // if specified row is null then we select the default one and we should replace the route in history
      // instead of navigating to a new route, so a back action will skip the base url with no item selected
      const isDefaultRowSelected = !row;
      this.router.navigate([itemKey], { relativeTo: this.route, replaceUrl: isDefaultRowSelected });
    }

    // update the selected item key
    this.selectedRowKey = itemKey;
    this.rowSelected.emit(rowToBeSelected);

    this.onRowSelected(itemKey);
  }

  @AnalyticsEvent({
    eventName: GeneralAnalyticsEvent.LIST_TABLE_ITEM_SELECT,
    eventInfo: (_, [itemKey]) => ({ selectedItemReference: itemKey })
  })
  onRowSelected(_itemKey: string) {
    return;
  }

  cacheTableHeaders(headers: ListTableHeader[] = []) {
    this.tableHeadersMap = {};
    this.displayedHeaders = [];
    this.hasFilterableFields = false;

    headers.forEach((header) => {
      if (header.defaultSort) {
        this.defaultSortColumn = header.fieldName;
        this.defaultSortDirection = header.defaultSort;
      }

      if (header.filterable) {
        this.hasFilterableFields = true;
      }

      // no point in showing headers if one cannot click on them to sort
      // but we still may want to keep it as a table header
      // so we can filter on this particular field
      if (typeof header.sortable === 'undefined' || header.sortable === true) {
        this.displayedHeaders.push(header.fieldName);
      }
      this.tableHeadersMap[header.fieldName] = header;
    });
  }

  getDataSource(data: any[] = []): MatTableDataSource<any> {
    const dataSource = new MatTableDataSource(data);
    dataSource.paginator = this.paginator.controller;
    dataSource.sort = this.sort;
    dataSource.filter = this.filter;

    dataSource.sortingDataAccessor = (item, field) => {
      if (this.tableHeadersMap[field].valueAccessor) {
        return this.tableHeadersMap[field].valueAccessor(item);
      }
      return item[field];
    };

    dataSource.filterPredicate = (item, filterString) => {
      if (!this.hasFilterableFields) {
        return true;
      }

      const lowerCaseFilter = filterString.toLocaleLowerCase();
      for (const header of this.headers) {
        if (header.filterable) {
          const colValue = header.valueAccessor
            ? header.valueAccessor(item)?.toLocaleLowerCase()
            : item[header.fieldName]?.toLocaleLowerCase();
          if (colValue?.includes(lowerCaseFilter)) {
            return true;
          }
        }
      }
      return false;
    };

    // if the data changes we need to manually sort the data
    dataSource.data = dataSource._orderData(data);
    return dataSource;
  }

  registerFilterString(filterString: string): void {
    // stop the execution if there is no data source
    if (!this.dataSource) {
      return;
    }

    // set the new filter string and emit the filtered data
    this.dataSource.filter = filterString;
    const filteredData = this.dataSource.filteredData || [];
    this.filteredData.emit(filteredData);

    // stop the execution if we cannot select a row
    if (!this.isRowSelectable) {
      return;
    }

    // check if the current selected item is part of the filtered data, to keep the current selection
    const currentSelection = filteredData.find((item) => this.keyAccessor(item) === this.selectedRowKey);

    if (currentSelection) {
      this.selectRow(currentSelection);
      return;
    }

    // select the first filter result (or null) if previous selection is not part of filtered data, but rows are selectable
    if (this.selectFirstItemOnFilter) {
      this.dataSource.filteredData?.length
        ? this.selectRow(this.dataSource.filteredData[0])
        : this.dataSource.data.length && this.rowSelected.emit(null);
    }
  }

  selectRowByPathReference(): void {
    // if the route change is triggered by clicking on a different item in the table
    // avoid "selecting" the same item twice
    const selectedRowKey = this.route.snapshot.firstChild ? this.route.snapshot.firstChild.params['id'] : '';
    if (selectedRowKey === this.selectedRowKey || !this.isRowSelectable) {
      return;
    }

    const itemToBeSelectedIndex = this.getItemIndex(this.dataSource.filteredData, selectedRowKey);

    if (itemToBeSelectedIndex === -1) {
      this.selectRow(null);
      return;
    }

    this.selectRow(this.dataSource.data[itemToBeSelectedIndex]);
  }

  ngOnInit() {
    // get the base url for further comparisons
    this.baseUrl = getBaseUrlFromRouteSnapshot(this.route.snapshot);

    this.cacheTableHeaders(this.headers);
    this.dataSource = this.getDataSource(this.data);

    this.sub = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => this.selectRowByPathReference());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (Array.isArray(changes['data']?.currentValue) && changes['data'].currentValue.length) {
      this.dataSource = this.getDataSource(changes['data'].currentValue);

      // reset previously selected item, so the table emits a new selection with new data
      this.selectedRowKey = null;
      this.selectRowByPathReference();

      if (this.filter !== undefined) {
        this.registerFilterString(this.filter);
      }
    }
    if (changes['headers']?.currentValue) {
      this.cacheTableHeaders(changes['headers'].currentValue);
    }
    if (changes['filter']?.currentValue !== undefined) {
      this.registerFilterString(changes['filter'].currentValue);
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
