import { Environment } from '@core/models/environment.model';

declare global {
  interface Window {
    adobeDataLayer: Array<any>;
  }
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function loadAdobeAnalytics(env: Environment) {
  return () => {
    // declare the adobe data layer array so we can interract before the script loads
    // and to have a fallback on local environment
    window.adobeDataLayer = window.adobeDataLayer || [];

    // don't load the adobe analytics script on local environment
    // regardless of selected configuration (dev, opt, prod)
    if (window.location.hostname === 'localhost') {
      return;
    }

    const scriptEl = document.createElement('script');
    scriptEl.setAttribute('async', '');
    scriptEl.src = env.adobeAnalyticsSourceURL;

    document.head.appendChild(scriptEl);
  };
}
