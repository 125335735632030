import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { Time } from '@shared/components/time-input/time-input.component';
import { DATE_FORMATS } from '@shared/pipes/localized-date/localized-date.pipe';
import { CUSTOM_MAT_FORMATS, CustomMatDateAdapter } from '@shared/utils/material-date-adapter';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';

export interface HighlightDate {
  className: string;
}

@Component({
  selector: 'datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: DatepickerComponent },
    { provide: DateAdapter, useClass: CustomMatDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_MAT_FORMATS }
  ]
})
export class DatepickerComponent implements ControlValueAccessor, OnInit, OnDestroy {
  dateStringControl: FormControl;
  dateControl: FormControl;
  timeStringControl: FormControl;
  timeControl: FormControl;
  displayPicker = false;

  @Input() min: Date;
  @Input() max: Date;
  @Input() readonly = false;
  @Input() pickTime = false;
  // this is added to keep 2 versions for now , later will remove this and keep the single class name for all
  @Input() className = 'date-input';
  @Input() highlightDates = new Map<string, HighlightDate>();
  @Input() showGreyInput = false;
  private sub = new Subscription();

  onChange: (value: Date) => void;
  onTouched: () => void;

  constructor() {}

  @HostListener('click')
  onHostClick() {
    // if there's a click anywhere inside the datepicker component
    // mark the input as touched
    if (this.onTouched) {
      this.onTouched();
    }
  }

  parseTime(timeString: string): Time {
    const nullTime: Time = { hours: 0, minutes: 0 };
    if (!/\d\d:\d\d/.test(timeString)) {
      return nullTime;
    }
    const [hours, minutes] = timeString.split(':');
    if (+hours > 24 || +minutes > 59) {
      return nullTime;
    }
    return { hours: +hours, minutes: +minutes };
  }

  parseDate(dateString: string): Date {
    const parsedDate = dayjs(dateString, CUSTOM_MAT_FORMATS.parse.dateInput);
    return parsedDate.toDate();
  }

  formatTime(time: Time): string {
    const hoursPadding = time.hours <= 9 ? '0' : '';
    const minutesPadding = time.minutes <= 9 ? '0' : '';
    return `${hoursPadding}${time.hours}:${minutesPadding}${time.minutes}`;
  }

  formatDate(date: Date): string {
    if (!date) {
      return '';
    }
    return dayjs(date).format(CUSTOM_MAT_FORMATS.display.dateInput);
  }

  setTime(date: Date, time: Time): Date {
    const timedDate = new Date(date);
    timedDate.setHours(time.hours);
    timedDate.setMinutes(time.minutes);
    timedDate.setSeconds(isNaN(time.seconds) ? 0 : time.seconds);
    return timedDate;
  }

  emitValue(value: Date) {
    if (this.onChange) {
      this.onChange(value);
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }

  writeValue(value: Date): void {
    this.dateStringControl.patchValue(this.formatDate(value), { emitEvent: false });
    this.dateControl.patchValue(value, { emitEvent: false });
    if (!this.pickTime) {
      return;
    }
    const time: Time = {
      hours: value.getHours(),
      minutes: value.getMinutes()
    };
    this.timeStringControl.patchValue(this.formatTime(time), { emitEvent: false });
    this.timeControl.patchValue(time, { emitEvent: false });
  }

  registerOnChange(fn: (value: Date) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  togglePicker() {
    this.displayPicker = !this.displayPicker;
  }

  updateSelectedDate(date: Date) {
    this.dateControl.patchValue(date);
    if (!this.pickTime) {
      this.togglePicker();
    }
  }

  getCurrentDateTime() {
    const todaysDate = new Date();
    const h = todaysDate.getHours();
    const m = todaysDate.getMinutes();
    const s = todaysDate.getSeconds();

    return { currentHours: h, currentMinutes: m, currentSeconds: s };
  }

  ngOnInit(): void {
    // date and time controls work with real data (Date and Time objects)
    // dateString and timeString controls work with the string representations
    // additional effort to keep the real value and it's string representation in sync is needed
    // because of the lack of time picking support from angular material
    this.dateControl = new FormControl(this.min || new Date());
    this.dateStringControl = new FormControl('');

    this.timeControl = new FormControl({ hours: 0, minutes: 0, seconds: 0 });
    this.timeStringControl = new FormControl('');

    this.sub.add(
      this.dateStringControl.valueChanges.subscribe((value) => {
        const parsedDate = this.parseDate(value);

        // if the date is invalid, e.g. the user just cleared the date input
        // emit null through the form control
        if (isNaN(parsedDate.getTime())) {
          this.emitValue(null);
          return;
        }
        const { currentHours, currentMinutes, currentSeconds } = this.getCurrentDateTime();
        let timedDate = this.setTime(parsedDate, {
          hours: currentHours,
          minutes: currentMinutes,
          seconds: currentSeconds
        });

        this.dateControl.patchValue(timedDate, { emitEvent: false });
        if (this.pickTime) {
          timedDate = this.setTime(timedDate, this.timeControl.value);
        }
        this.emitValue(timedDate);
      })
    );
    this.sub.add(
      this.dateControl.valueChanges.subscribe((value) => {
        const { currentHours, currentMinutes, currentSeconds } = this.getCurrentDateTime();
        let timedDate = this.setTime(value, { hours: currentHours, minutes: currentMinutes, seconds: currentSeconds });
        if (this.pickTime) {
          const time = this.timeControl.value;
          timedDate = this.setTime(value, time);
        }
        this.dateStringControl.patchValue(this.formatDate(timedDate), { emitEvent: false });
        this.emitValue(timedDate);
      })
    );

    this.sub.add(
      this.timeStringControl.valueChanges.subscribe((value) => {
        const time = this.parseTime(value);
        this.timeControl.patchValue(time, { emitEvent: false });
        const timedDate = this.setTime(this.dateControl.value, time);
        this.emitValue(timedDate);
      })
    );

    this.sub.add(
      this.timeControl.valueChanges.subscribe((time: Time) => {
        this.timeStringControl.patchValue(this.formatTime(time), { emitEvent: false });
        const timedDate = this.setTime(this.dateControl.value, time);
        this.emitValue(timedDate);
      })
    );
  }

  highlightDateClass = (inputDate: Date): string => {
    const formattedDate = dayjs(inputDate).format(DATE_FORMATS.mediumDate);
    const className = this.highlightDates.get(formattedDate)?.className;
    return className;
  };

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
