import { InjectionToken } from '@angular/core';

export type AnalyticsViewContext = string;

export const ANALYTICS_VIEW_CONTEXT = new InjectionToken<AnalyticsViewContext>('ANALYTICS_VIEW_CONTEXT', {
  providedIn: 'root',
  factory: () => 'sp:default-view'
});

export enum GeneralAnalyticsEvent {
  PAGE_VIEW = 'page_view',
  LIST_TABLE_ITEM_SELECT = 'list_table_item_select',
  DATA_TABLE_ITEM_SELECT = 'data_table_item_select',
  EXPORT_EXCEL = 'export_excel',
  CONSOLE_ERROR = 'console_error'
}

export type AnalyticsEventData = Record<string, any>;
