import { Equipment, EquipmentDetails } from '@admin-facade/models/equipment-data.model';
import { EquipmentService } from '@admin-facade/services/admin-facade/equipment/equipment.service';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { QtyLengthPipe } from '@shared/pipes/qty-length/qty-length.pipe';
import { EquipmentServiceDistance } from '@todo/model/equipment-settings.model';

@Pipe({
  name: 'equipmentIdentifier'
})
export class EquipmentIdentifierPipe implements PipeTransform {
  constructor(private equipmentService: EquipmentService) {}
  transform(equipment: EquipmentDetails): string {
    return this.equipmentService.getEquipmentIdentifierText(equipment)[0];
  }
}

@Pipe({
  name: 'equipmentOtherIdentifiers'
})
export class EquipmentOtherIdentifiersPipe implements PipeTransform {
  constructor(private equipmentService: EquipmentService) {}
  transform(equipment: Equipment): string {
    const identifiers = this.equipmentService.getEquipmentIdentifierText(equipment);
    return identifiers.slice(1).join(', ');
  }
}

@Injectable({
  providedIn: 'root'
})
@Pipe({
  name: 'equipmentSettingsIdentifier'
})
export class EquipmentSettingsIdentifierPipe implements PipeTransform {
  constructor(private equipmentIdentifierPipe: EquipmentIdentifierPipe, private qtyLengthPipe: QtyLengthPipe) {}
  transform(equipment: Equipment, serviceDistance: EquipmentServiceDistance): string {
    return [
      this.equipmentIdentifierPipe.transform(equipment),
      `(${this.qtyLengthPipe.transform(serviceDistance.distance)})`
    ].join(' ');
  }
}
