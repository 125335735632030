import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NoNetworkService } from '@shared/services/no-network/no-network.service';
import { catchError, Observable, of, throwError } from 'rxjs';

@Injectable()
export class NoNetworkInterceptor implements HttpInterceptor {
  constructor(private noNetworkService: NoNetworkService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (!this.noNetworkService.isNetworkAvailable() && err.status === 504) {
          this.router.navigate(['no-network', { goBackTo: this.router.url }]);
          return of(null);
        } else {
          return throwError(() => err);
        }
      })
    );
  }
}
