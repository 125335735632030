// istanbul ignore file
import { inject, Injectable } from '@angular/core';
import { UserProfile } from '@core/models/user-profile.model';
import { AdobeAnalyticsService } from '@core/services/adobe-analytics/adobe-analytics.service';
import { AnalyticsEventData } from '@core/services/analytics/analytics';
import { DatadogService } from '@core/services/datadog/datadog.service';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  private adobeAnalyticsService = inject(AdobeAnalyticsService);
  private datadogService = inject(DatadogService);

  init(userProfile: UserProfile) {
    this.adobeAnalyticsService.init(userProfile);
    this.datadogService.init(userProfile);
  }

  pushEvent(eventName: string, viewContext: string, eventData: AnalyticsEventData = {}) {
    // Adaobe Analytics requires view context to be provided with every event
    this.adobeAnalyticsService.pushEvent(eventName, { viewContext, ...eventData });
    // Datadog automatically keeps track of the view context for the action
    this.datadogService.pushEvent(eventName, eventData);
  }

  pushPageViewEvent(viewContext: string) {
    this.adobeAnalyticsService.pushPageViewEvent(viewContext);
    this.datadogService.pushPageViewEvent(viewContext);
  }
}
