import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

export interface BarChartConfig {
  title: string;
  label: string[];
  data: number[];
}

@Component({
  selector: 'bar-chart',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './bar-chart.component.html',
  styleUrl: './bar-chart.component.scss'
})
export class BarChartComponent {
  chartConfig = input<BarChartConfig>();
  containerHeight = 300;
  maxValue = computed(() => Math.max(...this.chartConfig().data));
  normalizedData = computed(() =>
    this.chartConfig().data.map((value) => (value / this.maxValue()) * this.containerHeight)
  );
}
