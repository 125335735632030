import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription, tap } from 'rxjs';

@Component({
  selector: 'search',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule],
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: SearchComponent }]
})
export class SearchComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() placeholder = '';
  @Input() size = 'sm'; // sm| md
  @Input() bgColor = '';
  @Input() icon: string;
  // fallback event if component is used without a form control
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  onChange: (value: string) => void;
  onTouched: () => void;

  searchControl: FormControl;

  private sub: Subscription;

  constructor() {}

  writeValue(value: string): void {
    this.searchControl.patchValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    this.searchControl = new FormControl('');
    this.sub = this.searchControl.valueChanges.pipe(tap((value) => this.valueChange.emit(value))).subscribe((value) => {
      if (this.onChange) {
        this.onChange(value);
      }
      if (this.onTouched) {
        this.onTouched();
      }
    });
  }

  onClear(): void {
    this.searchControl.setValue('');
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
