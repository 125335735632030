import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})

export class SearchFilterPipe implements PipeTransform {

  transform(items: any[], searchText: string, fieldNames: string[]): any[] {
    if (!items) { return []; }
    if (!searchText) { return items; }
    searchText = searchText.toLowerCase();
    return items.filter(item => {
      if (item) {
        for (const fieldName of fieldNames) {
          if (String(item[fieldName]).toLowerCase().includes(searchText))
            return true;
        }
      }
      return false;
    });
  }
}
