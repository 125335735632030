import { Injector } from '@angular/core';
import { ANALYTICS_VIEW_CONTEXT } from '@core/services/analytics/analytics';
import { AnalyticsService } from '@core/services/analytics/analytics.service';

export type AnalyticsEventInfoAccessor<C, T> = (context: C, args: any[], result: any) => T;

export interface AnalyticsEventConfig<C, T> {
  /** name of the event to be pushed */
  eventName: string;
  /** the `viewContext` property will be automatically appended to specified `eventInfo` for AdobeAnalytics */
  eventInfo?: AnalyticsEventInfoAccessor<C, T> | T;
}

export type AnalyticsEvent<C, T = void> = (eventConfig: AnalyticsEventConfig<C, T>) => MethodDecorator;
export const AnalyticsEvent =
  <C, T = void>(eventConfig: AnalyticsEventConfig<C, T>): MethodDecorator =>
  (_target: C, _key: string, descriptor?: any) => {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
      /** component that gets its methods decorated needs to have the injector specified as dependency
       * in order to get a reference of the analytics service and the view context */
      const injector: Injector = this.injector;
      if (!injector) {
        throw new Error(
          `
            Component ${_target.constructor.name} needs to specify "injector: Injector" as dependency to support "AnalyticsEvent" decorations.
            Either extend "AnalyticsProvider" class, or inject "injector: Injector" as dependency in this component.
          `
        );
      }

      const analyticsService = injector.get(AnalyticsService);
      const viewContext = injector.get(ANALYTICS_VIEW_CONTEXT);
      const result = originalMethod.apply(this, args);

      /** get the `eventInfo` property that would be stored with the event */
      let eventInfo: T;

      if (typeof eventConfig.eventInfo === 'object') {
        eventInfo = eventConfig.eventInfo;
      } else {
        eventInfo =
          eventConfig?.eventInfo &&
          (eventConfig.eventInfo as AnalyticsEventInfoAccessor<C, T>)(this as C, args, result);
      }

      analyticsService.pushEvent(eventConfig.eventName, viewContext, eventInfo);

      return result;
    };

    return descriptor;
  };
