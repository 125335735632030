import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { defineCustomElements as scope, applyPolyfills } from '@myscania/scope/dist/loader';

import { defineCustomElements as sdds, addTheme } from '@scania/components';
import { theme as scaniaTheme } from '@scania/theme-light';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { defineCustomElements as myScaniaChat } from '@myscania/myscania-chat-widget/loader';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

applyPolyfills().then(() => {
  scope();
  sdds();
  addTheme(scaniaTheme);
  myScaniaChat();
});
