import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Route, Router } from '@angular/router';

export interface TabConfig extends Route {
  /** translation key for the title to be displayed in the tab selector */
  title: string;
  /** counter to be displayed next to the tab title */
  counter?: number;
  /** unique path to be declared in routing module */
  path?: string;
  /** the value of this property is used to redirect to external paths */
  absolutePath?: string;
  /** component to render for the tab content */
  component?: any;
  /** if isDefault is set to true, when the root url of the tabular-view component
   * is opened this tab will be selected by default */
  isDefault?: boolean;
  /** if isDisabled is set to true, the tab head will be visible but it will be disabled and the route inaccessible */
  isDisabled?: boolean;
  /** if hasWarning is set to true, the counter will not be displayed, and the tab head will show a warning icon */
  hasWarning?: boolean;
}

export const getBaseUrlFromRouteSnapshot = (route: ActivatedRouteSnapshot) => {
  let node = route;
  let fragments = '';
  while (node && node.routeConfig) {
    fragments = node.routeConfig.path ? '/' + node.routeConfig.path + fragments : fragments;
    node = node.parent;
  }
  return fragments;
};

export const canActivateTabRoute: (tab: TabConfig) => CanActivateFn =
  (tab: TabConfig) => (route: ActivatedRouteSnapshot) => {
    if (!tab.isDisabled) {
      return true;
    }

    const router = inject(Router);
    const baseUrl = getBaseUrlFromRouteSnapshot(route.parent);
    return router.createUrlTree([baseUrl]);
  };

export const getTabRoutes: (tabs: TabConfig[]) => Route[] = (tabs) =>
  tabs
    .filter((tab) => !tab.absolutePath && !tab.isDisabled)
    .map((tab) => ({
      path: tab.path,
      component: tab.component,
      children: tab.children,
      canActivate: [canActivateTabRoute(tab)]
    }));
