import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateRange } from '@shared/components/date-range-picker/date-range-picker.component';
import { ButtonComponentOutput, ButtonDropdownItem, ButtonType } from '@teamsp/components/button';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';

export interface PageHeaderFilterConfig {
  search?: {
    placeholder: string;
  };
  dateRange?: {
    placeholder?: string;
    default?: DateRange;
  };
  select?: {
    items: any[];
    placeholder: string;
    default?: any;
    label?: string;
  };
}

export interface PageHeaderActionConfig {
  title: string;
  redirect?: string;
  items?: ButtonDropdownItem[];
  type?: ButtonType;
  action?: (output: ButtonComponentOutput) => void;
}

export interface PageHeaderFilter {
  dateRange?: DateRange;
  search?: string;
  select?: any;
}

export interface PageHeaderBadgeConfig {
  title?: string;
  count?: number;
  action?: (output: ButtonComponentOutput) => void;
}

const DEFAULT_FILTER_CONFIG: PageHeaderFilterConfig = {};
const DEFAULT_ACTIONS_CONFIG: PageHeaderActionConfig[] = [];

@Component({
  selector: 'page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit, OnDestroy {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() badgeConfig: PageHeaderBadgeConfig;
  @Input() filterConfig: PageHeaderFilterConfig = DEFAULT_FILTER_CONFIG;
  @Input() actionsConfig: PageHeaderActionConfig[] = DEFAULT_ACTIONS_CONFIG;
  @Input() maxDate: Date;
  @Output() filter: EventEmitter<PageHeaderFilter> = new EventEmitter();

  ButtonType = ButtonType;

  startDateToShow = '';
  endDateToShow = '';
  filterForm: FormGroup<{
    dateRange: FormControl<DateRange>;
    search: FormControl<string>;
    select: FormControl<any>;
  }>;
  sub: Subscription;
  showClearIcon = false;
  constructor() {}

  ngOnInit(): void {
    const isRangeEnabled = !!this.filterConfig.dateRange;
    const isSearchEnabled = !!this.filterConfig.search;
    const isSelectEnabled = !!this.filterConfig.select;

    const hasDefaultRange = isRangeEnabled && !!this.filterConfig.dateRange.default;
    const hasDefaultSelectedValue = isSelectEnabled && !!this.filterConfig.select.default;

    this.filterForm = new FormGroup({
      dateRange: new FormControl(this.filterConfig?.dateRange?.default || null),
      search: new FormControl('', Validators.required),
      select: new FormControl(this.filterConfig?.select?.default || null, Validators.required)
    });

    this.sub = this.filterForm.valueChanges.subscribe(({ dateRange, search, select }) =>
      // emit new filtering options based on the filter config
      this.filter.emit({
        ...(isRangeEnabled && { dateRange }),
        ...(isSearchEnabled && { search }),
        ...(isSelectEnabled && { select })
      })
    );

    // emit default filter selection
    if ((isRangeEnabled && !hasDefaultRange) || (isSelectEnabled && !hasDefaultSelectedValue)) {
      const defaultRange = this.filterForm.value.dateRange || this.getDefaultDateRange();

      const firstItem = this.filterConfig.select.items.length ? this.filterConfig.select.items[0] : null;
      const defaultSelectedValue = this.filterForm.value.select || firstItem;

      this.filterForm.patchValue({
        dateRange: defaultRange,
        select: defaultSelectedValue
      });
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private getDefaultDateRange(): DateRange {
    const end = new Date();
    // TODO: this needs to be synced with all page-header instances that display date ranges
    // the default value is last 3 months
    // check fmp-frontend: state-resolve.util.js for more details
    const start = dayjs(end).subtract(3, 'month').toDate();
    return { start, end };
  }
}
