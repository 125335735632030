import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ENVIRONMENT_TOKEN, Environment } from '@core/models/environment.model';
import { UserProfile } from '@core/models/user-profile.model';
import { LDClient, LDContext, LDFlagSet, LDMultiKindContext, initialize } from 'launchdarkly-js-client-sdk';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LaunchDarklyService implements OnDestroy {
  private ldClient: LDClient;

  constructor(@Inject(ENVIRONMENT_TOKEN) private environment: Environment) {}

  private createContext(profile: UserProfile): LDContext {
    return {
      kind: 'multi',
      user: {
        key: profile.externalStaffReference,
        name: `${profile.firstName} ${profile.lastName}`,
        email: profile.email,
        lang: profile.language,
        _meta: {
          // email is private
          privateAttributes: ['email', 'name']
        }
      },
      customer: {
        key: profile.customer.externalCustomerReference,
        name: `${profile.customer.name} | ${profile.customer.country}`,
        country: profile.customer.country,
        tz: profile.customer.timeZone,
        lang: profile.customer.language
      },
      country: {
        key: profile.customer.externalCountryReference,
        name: profile.customer.country
      }
    } as LDMultiKindContext;
  }

  public getFlags(profile: UserProfile): Observable<LDFlagSet> {
    // close any pre-existing client
    if (this.ldClient) {
      this.ldClient.close();
    }

    this.ldClient = initialize(this.environment.launchdarklyClientId, this.createContext(profile));

    const response = new Subject<LDFlagSet>();

    this.ldClient
      .waitForInitialization()
      .then(() => {
        const flags = this.ldClient.allFlags();
        response.next(flags);
      })
      .catch((err) => response.error(err));

    return response.asObservable();
  }

  async ngOnDestroy() {
    if (this.ldClient) {
      return await this.ldClient.close();
    }
  }
}
