import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_TOKEN, Environment } from '@core/models/environment.model';

declare let Pastease: any;
@Injectable({
  providedIn: 'root'
})
export class MopinionService {
  initialized = false;

  constructor(@Inject(ENVIRONMENT_TOKEN) private env: Environment) {
    this.init();
  }

  public init(): void {
    const js = document.createElement('script');
    js.setAttribute('type', 'text/javascript');
    js.setAttribute('id', 'mopinion-form-script');
    js.setAttribute('src', `//deploy.mopinion.com/js/pastease.js?updated=${new Date().getTime()}`);
    js.async = true;
    document.getElementsByTagName('head')[0].appendChild(js);
  }

  private refresh(): void {
    const t = setInterval(() => {
      try {
        Pastease.forceParse();
        clearInterval(t);
      } catch (e) {}
    }, 50);
  }

  public load(cultureCode: string): void {
    document.cookie = `COOKIE_LOCALE_LANG="${cultureCode}"`;
    if (this.initialized) {
      this.refresh();
      return;
    }

    const t = setInterval(() => {
      try {
        Pastease.load(this.env.mopinionDeploymentId);
        this.initialized = true;
        clearInterval(t);
      } catch (e) {}
    }, 50);
  }
}
