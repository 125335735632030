import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '@shared/components/modal/modal.component';
import {
  ModalConfig,
  ModalContentComponent,
  ModalEventType,
  ModalInstance,
  DEFAULT_MODAL_CONFIG,
  ModalEventName
} from '@shared/models/modal';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(private ngbModal: NgbModal) {}

  open<
    CType extends ModalContentComponent<RType, EName>,
    RType = ReturnType<CType['valueAccessor']>,
    EName extends ModalEventName = ModalEventName
  >(config: ModalConfig<CType, RType, EName>): ModalInstance<CType, RType, EName> {
    const modalConfig: ModalConfig<CType, RType, EName> = {
      ...(DEFAULT_MODAL_CONFIG as ModalConfig<CType, RType, EName>),
      ...config
    };
    let modalCompInstance: ModalComponent<CType, RType, EName> = null;

    const ngbModalOptions: NgbModalOptions = {
      animation: false,
      backdrop: modalConfig.dismissible,
      size: modalConfig.size,
      keyboard: modalConfig.dismissible,
      windowClass: modalConfig.popoverStyle,
      centered: true,
      injector: modalConfig?.injector,
      beforeDismiss: () => {
        if (modalCompInstance.disabledEvents[ModalEventType.DISMISS]) {
          return false;
        }
        modalCompInstance.modalClose.emit({ eventType: ModalEventType.DISMISS });
        return true;
      }
    };

    const modalRef = this.ngbModal.open(ModalComponent, ngbModalOptions);
    modalCompInstance = modalRef.componentInstance as ModalComponent<CType, RType, EName>;
    modalCompInstance.config = config;

    // prepare open modal instance
    const modal: ModalInstance<CType, RType, EName> = {
      close: modalCompInstance.modalClose.pipe(take(1))
    };

    if (modalConfig.contentComponent) {
      modal.contentComponentInit = modalCompInstance.contentComponentInit.pipe(take(1));
      // when the modal content has init, save the reference in the modal instance
      modalCompInstance.contentComponentInit
        .pipe(take(1))
        .subscribe((comp: CType) => (modal.contentComponentInstance = comp));
    }

    return modal;
  }

  close() {
    if (this.ngbModal.hasOpenModals()) {
      this.ngbModal.dismissAll();
    }
  }
}
