import { MapLanguage } from '@shared/models/map';

export const MAP_LANGUAGES: MapLanguage[] = [
  { Culture: 'de-DE', HereUiCode: 'de-DE', HereCode: 'GER', Label: 'German' },
  { Culture: 'en-GB', HereUiCode: 'en-US', HereCode: 'ENG', Label: 'English' },
  { Culture: 'es-ES', HereUiCode: 'es-ES', HereCode: 'SPA', Label: 'Spanish' },
  { Culture: 'fr-FR', HereUiCode: 'fr-FR', HereCode: 'FRE', Label: 'French' },
  { Culture: 'nl-NL', HereUiCode: 'nl-NL', HereCode: 'DUT', Label: 'Dutch' },
  { Culture: 'pl-PL', HereUiCode: 'pl-PL', HereCode: 'POL', Label: 'Polish' },
  { Culture: 'pt-PT', HereUiCode: 'pt-PT', HereCode: 'POR', Label: 'Portuguese' },
  { Culture: 'tr-TR', HereUiCode: 'tr-TR', HereCode: 'TUR', Label: 'Turkish' },
  { Culture: 'ru-RU', HereUiCode: 'ru-RU', HereCode: 'RUS', Label: 'Russian' },
  { Culture: 'zh-CN', HereUiCode: 'zh-CN', HereCode: 'CHI', Label: 'Simplified chinese' },
  { Culture: 'zh-TW', HereUiCode: '', HereCode: 'CHT', Label: 'Traditional chinese' },
  { Culture: 'it-IT', HereUiCode: 'it-IT', HereCode: 'ITA', Label: 'Italian' },
  { Culture: 'gd-Latn', HereUiCode: '', HereCode: 'GLE', Label: 'Gaelic' },
  { Culture: 'el-GR', HereUiCode: '', HereCode: 'GRE', Label: 'Greek' },
  { Culture: 'he-IL', HereUiCode: '', HereCode: 'HEB', Label: 'Hebrew' },
  { Culture: 'hi-IN', HereUiCode: '', HereCode: 'HIN', Label: 'Hindi' },
  { Culture: 'id-ID', HereUiCode: '', HereCode: 'IND', Label: 'Indonesian' },
  { Culture: 'fa-IR', HereUiCode: '', HereCode: 'PER', Label: 'Persian' },
  { Culture: '', HereUiCode: '', HereCode: 'SIN', Label: 'Sinhalese' },
  { Culture: 'th-TH', HereUiCode: '', HereCode: 'THA', Label: 'Thai' },
  { Culture: 'uk-UA', HereUiCode: '', HereCode: 'UKR', Label: 'Ukrainian' },
  { Culture: 'ur-PK', HereUiCode: '', HereCode: 'URD', Label: 'Urdu' },
  { Culture: 'vi-VN', HereUiCode: '', HereCode: 'VIE', Label: 'Vietnamese' },
  { Culture: 'cy-GB', HereUiCode: '', HereCode: 'WEL', Label: 'Welsh' },
  { Culture: 'ar-SA', HereUiCode: '', HereCode: 'ARA', Label: 'Arabic' }
];

export const MAP_TILE = {
  size: 256,
  ppi: 72
};

export const SCALEBAR_STYLE = {
  position: 'absolute',
  bottom: '2rem',
  right: '2rem'
};

export const MAP_DEFAULTS = {
  zoom: {
    withCoordinates: 11,
    noCoordinates: 4
  },
  coordinates: {
    latitude: 56.562176,
    longitude: 13.721087
  }
};

export const DEFAULT_ICON_HTML = `
  <div data-custom-location-pin class="location-pin-container">
    <span id="locationPin" class="location-pin"></span>
    <span class="shadow"></span>
  </div>`;

export const WORKSHOP_ICON = {
  regular: 'assets/images/workshop-pin.png',
  active: 'assets/images/workshop-pin-active.png'
};
