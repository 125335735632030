// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from '@core/models/environment.model';

export const environment: Environment = {
  administration: 'https://fmp-administration.test.cs.scania.com/#/administration',
  adobeAnalyticsSourceURL: 'https://assets.adobedtm.com/03cc06ac496b/bf19cf3d16f8/launch-dd9066290f47-staging.min.js',
  apiKey: 'da2-djxdi7h5jfbw7e3z2vwdecrdbm',
  auth: 'https://auth.testapicw.cs.scania.com/api/',
  crossStroageHub: 'https://fmp-test.cw.csawsdev.net/customer-portal/assets/cross-storage/hub.html',
  datadog: {
    appId: '556a06ca-1db7-4d7a-a496-7202beb9c97e',
    appName: 'service-planning',
    clientToken: 'pub9f67151f3ca67e413ec195a61121b785'
  },
  facadeBaseAws: 'https://adminfacade.testapicw.cs.scania.com',
  features: 'https://fmsoptfeaturetoggle.scania.com/featuretoggle/v2',
  graphqlCheckListURL: 'https://sp-check-api.testapisp.cs.scania.com/graphql',
  hereMapsApiKey: 'w80vUka5vOxeGmLl8ppK6IZ2UmmeUeGC7NAxPwyg6AQ',
  imageUploadURLPrefix: 'https://sp-check-api.testapisp.cs.scania.com/images',
  imageApi: 'https://images.testapicw.cs.scania.com',
  keycloak: {
    issuer: 'https://mylogin.ciam.preprod.aws.scania.com/auth',
    realm: 'fg-ext',
    clientId: 'cs_fmp_service_planning_app'
  },
  launchdarklyClientId: '668bd40cfecbdd10119b473c',
  mopinionDeploymentId: '9j25xdTTtSrdg4drNCSZJVxkWwpkipBpPzAOsAMj',
  myScaniaFmpURL: 'https://fmp.myscania.test.cs.scania.com',
  myScaniaURL: 'https://myscania.test.cs.scania.com',
  name: 'test',
  production: false,
  serviceBaseServicePlanning: 'https://sp-frontendgateway-api.testapisp.cs.scania.com',
  xClientName: 'fmp-serviceplanning-app'
};
