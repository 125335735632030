/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TabConfig, getBaseUrlFromRouteSnapshot } from '@shared/components/tabular-view/tabular-view.model';
import { ComponentHostDirective } from '@shared/directives/component-host.directive';
import { Subscription } from 'rxjs';

@Component({
  selector: 'tabular-view',
  styleUrls: ['./tabular-view.component.scss'],
  templateUrl: './tabular-view.component.html'
})
export class TabularViewComponent implements OnInit, OnDestroy {
  @ViewChild(ComponentHostDirective, { static: true }) componentHost: ComponentHostDirective;
  @Input() tabs: TabConfig[] = [];

  private sub: Subscription = new Subscription();
  baseURL = '';

  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute
  ) {}

  hasCounter(tab: TabConfig) {
    return !isNaN(tab.counter);
  }

  selectTab(tab: TabConfig, isDefaultSelection?: boolean): void {
    if (!tab) {
      return;
    }
    // navigate to the selected tab's route if it's relative and not activated already
    // or redirect to it's absolute path it exists
    // check the tab is already activated, in which case skip navigation
    const isTabActivated = (this.activatedRoute.firstChild?.routeConfig as TabConfig)?.path === tab.path;
    if (tab.path && !isTabActivated) {
      this.router.navigate([this.baseURL, tab.path], { replaceUrl: isDefaultSelection });
    } else if (tab.absolutePath) {
      this.navigateToExternalURL(tab.absolutePath);
    }
  }

  navigateToExternalURL(url: string) {
    window.location.href = url;
  }

  getInitialTab() {
    const activatedRouteChild = this.activatedRoute.snapshot.firstChild;
    const defaultTab = this.tabs.find((tab) => tab.isDefault) || null;
    // if the activated route has any child routes activated
    // that means that we have a selected tab and that is the initial tab that the user will see
    if (activatedRouteChild && activatedRouteChild.routeConfig.path) {
      const activatedTab = this.tabs.find((tab) => tab.path === activatedRouteChild.routeConfig.path);
      return activatedTab ? activatedTab : defaultTab;
    }
    // otherwise we need to display the default tab
    return defaultTab;
  }

  ngOnInit() {
    // get the base url by parsing the routing tree
    // and save it in order to navigate to different tabs
    this.baseURL = getBaseUrlFromRouteSnapshot(this.activatedRoute.snapshot);

    const initialTab = this.getInitialTab();
    this.selectTab(initialTab, true);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
