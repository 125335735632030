import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[template-name]'
})
export class DataTableColumnDirective {
  constructor(public readonly template: TemplateRef<any>) {}

  @Input('template-name') columnName: string;
}
