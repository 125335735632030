import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Pipe({
  name: 'controlError',
  // even though in this case the pipe does not have any internal state
  // it depends on the state of the control argument, so even if the input itself does not change,
  // its state may change and the pipe needs to be called again
  pure: false
})
export class ControlErrorPipe implements PipeTransform {
  transform(control: AbstractControl, errorName?: string): boolean {
    // if error code is specified check for specific error
    if (errorName) {
      return control.touched && control.hasError(errorName);
    }
    // otherwise check if the control has any error
    return control.touched && control.invalid;
  }
}
