import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastMessageComponent, ToastMessageRedirect } from '@shared/components/toast-message/toast-message.component';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';

export type ToastrCallback = (message: string, title: string, override?: Partial<IndividualConfig>) => ActiveToast<any>;

export interface ToastConfig {
  title?: string;
  message?: string;
  redirect?: ToastMessageRedirect;
  icon?: string;
}

export type ToastMessage = ToastConfig | string;

@Injectable({
  providedIn: 'root'
})
export class ToastrMessageService {
  private successCB: ToastrCallback;
  private infoCB: ToastrCallback;
  private errorCB: ToastrCallback;
  private warningCB: ToastrCallback;

  constructor(
    private toastrService: ToastrService,
    private translate: TranslateService
  ) {
    this.successCB = this.toastrService.success.bind(this.toastrService);
    this.infoCB = this.toastrService.info.bind(this.toastrService);
    this.errorCB = this.toastrService.error.bind(this.toastrService);
    this.warningCB = this.toastrService.warning.bind(this.toastrService);
  }

  success(toastConfig: ToastMessage): void {
    this.invokeToastr(this.successCB, toastConfig);
  }

  info(toastConfig: ToastMessage): void {
    this.invokeToastr(this.infoCB, toastConfig);
  }

  error(toastConfig: ToastMessage): void {
    this.invokeToastr(this.errorCB, toastConfig);
  }

  warning(toastConfig: ToastMessage): void {
    this.invokeToastr(this.warningCB, toastConfig);
  }

  genericError(): void {
    // An error occurred. Please wait for a while and try again. If the problem persists, contact the Technical Helpdesk.
    this.error('EttTillfälligtFelUppstod_VäntaEnStundOchFörsökIgen_OmProblemet');
  }

  invokeToastr(toastrFunc: ToastrCallback, toastConfig: ToastMessage): void {
    let message = '';
    let title = '';

    if (typeof toastConfig === 'string') {
      message = this.translate.instant(toastConfig);
    } else {
      message = toastConfig.message ? this.translate.instant(toastConfig.message) : '';
      title = toastConfig.title ? this.translate.instant(toastConfig.title) : '';
    }

    const toast = toastrFunc(message, title, { onActivateTick: true });

    if (toast?.toastRef.componentInstance) {
      const instance = toast.toastRef.componentInstance as ToastMessageComponent;
      instance.header = title;
      instance.content = message;
      instance.redirect = (toastConfig as ToastConfig)?.redirect;
      instance.icon = (toastConfig as ToastConfig)?.icon;
    }
  }
}
