import { RequestMapper } from '@shared/mappers/mapper';

export class WorkshopHistoryRequestMapper extends RequestMapper {
  override requestParamsMap: any = {
    equipmentReference: 'ExternalEquipmentReference',
    equipmentGroupReference: 'EquipmentGroupReference',
    startDate: 'StartDate',
    endDate: 'EndDate'
  };
}
