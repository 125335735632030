export class EndPoint {
  version: string;
  uri: string;

  constructor(version: string, uri: string) {
    this.version = version;
    this.uri = uri;
  }

  public get URL(): string {
    return `${this.version}/${this.uri}`;
  }
}

export const AGREEMENT = new EndPoint('v1', 'agreement');
export const AVERAGE_DISTANCE = new EndPoint('v1', 'averagedrivendistance');
export const AVERAGE_RUNTIME = new EndPoint('v1', 'averageruntime');
export const BOOKING = new EndPoint('v1', 'booking');
export const BOOKING_PROPOSAL = new EndPoint('v1', 'booking-proposal');
export const BOOKING_V2 = new EndPoint('v2', 'booking');
export const BOOKING_V3 = new EndPoint('v3', 'bookings');
export const CALENDAR = new EndPoint('v1', 'calendar');
export const CUSTOMER_SETTINGS = new EndPoint('v1', 'customersettings');
export const DEALER_SERVICE = new EndPoint('v1', 'dealerservice');
export const DEFECT = new EndPoint('v1', 'defects');
export const EQUIPMENT = new EndPoint('v1', 'equipment');
export const EQUIPMENT_GROUP = new EndPoint('v1', 'equipmentgroup');
export const EQUIPMENT_SETTINGS = new EndPoint('v1', 'equipmentsettings');
export const MAINTENANCE_PLAN = new EndPoint('v1', 'maintenanceplan');
export const NOTIFICATION = new EndPoint('v1', 'notifications');
export const ODOMETER = new EndPoint('v1', 'totalodometer');
export const REPORT_RECIPIENT = new EndPoint('v2', 'reportrecipient');
export const REPORT_SETTINGS = new EndPoint('v1', 'reportsettings');
export const RUNTIME = new EndPoint('v1', 'totalruntime');
export const SERVICE_ACTIVITY = new EndPoint('v1', 'serviceactivity');
export const SERVICE_DISTANCE = new EndPoint('v1', 'serviceDistance');
export const SERVICE_NEEDS = new EndPoint('v1', 'service-needs');
export const STAFF = new EndPoint('v1', 'staff');
export const STAFF_BY_REF = new EndPoint('v2', 'staff');
export const SUPPORTED_LANGUAGE = new EndPoint('v1', 'localization');
export const SYMPTOM = new EndPoint('v1', 'symptom');
export const VEHICLE = new EndPoint('v1', 'vehicles');
export const WORKSHOP = new EndPoint('v1', 'workshops');
export const WORKSHOP_HISTORY = new EndPoint('v3', 'workshophistory');
export const NOTIFICATION_V2 = new EndPoint('v2', 'notifications');
