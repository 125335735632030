import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

export interface Time {
  hours: number;
  minutes: number;
  seconds?: number;
}

@Component({
  selector: 'time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: TimeInputComponent }]
})
export class TimeInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  timeGroup: FormGroup<{
    hours: FormControl<number>;
    minutes: FormControl<number>;
    seconds: FormControl<number>;
  }>;

  @Input() min: Time;
  @Input() max: Time;

  @Input() seconds = false;

  @Input() showConfirmCheck = true;

  @Output() confirm: EventEmitter<void> = new EventEmitter();

  private sub: Subscription;
  onChange: (value: Time) => void;
  onTouched: () => void;

  constructor() {}

  emitValue(value: Time) {
    if (this.onChange) {
      this.onChange(value);
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }

  writeValue(value: Time): void {
    this.timeGroup.setValue(
      { hours: value.hours, minutes: value.minutes, seconds: isNaN(value.seconds) ? 0 : value.seconds },
      { emitEvent: false }
    );
  }

  registerOnChange(fn: (value: Time) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    this.timeGroup = new FormGroup({
      hours: new FormControl(0),
      minutes: new FormControl(0),
      seconds: new FormControl(0)
    });

    this.sub = this.timeGroup.valueChanges.subscribe((time: Time) => this.emitValue(time));
  }

  formatNumber(value: number): string {
    return value.toString().padStart(2, '0'); // format the number with leading zeros
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
