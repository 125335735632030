import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataTableService {
  private selectedRef: BehaviorSubject<string> = new BehaviorSubject('');
  public previousRef = this.selectedRef.asObservable();

  constructor() {}

  updateReference(value: string) {
    this.selectedRef.next(value);
  }
}
