export enum SPPermission {
  CREATE_EDIT_DELETE_SERVICE_PLANNING = 'CreateEditDeleteServicePlanning',
  SHOW_SERVICE_PLANNING_PAGE = 'ShowServicePlanningPage',
  DEFECT_REPORTING = 'DefectReporting',
  EDIT_DEFECT = 'EditDefect',
  READ_SERVICE_PLANNING = 'ReadServicePlanning'
}

export enum Feature {
  CHECK_BEFORE_DRIVE = 'CheckBeforeDrive',
  SP_AUTO_PERFORMED_BOOKINGS = 'SPAutoPerformedBookings',
  SP_BOOKING_FLOW = 'SPBookingFlow',
  SP_BOOKING_NOTIFICATIONS = 'SPBookingNotifications',
  SP_CALENDAR_PAGE_ACCESS = 'SPCalendarPageAccess',
  SP_CHECKLIST_DVSA_TEMPLATE = 'SPChecklistDvsaTemplate',
  SP_OVERVIEW_PAGE_ACCESS = 'SPOverviewPageAccess',
  SP_STATIC_MISSED_CHECKS = 'SPStaticMissedChecks',
  SP_ZENDESK_WIDGET_ACCESS = 'SPZendeskWidgetAccess'
}

export enum LDFlag {
  /** used to enable datadog session recordings, only if user consents via cookie */
  DATADOG = 'analytics-datadog'
}

export interface UserProfile {
  externalStaffReference: string;
  firstName: string;
  lastName: string;
  email: string;
  telephoneNumber: string;
  adLogin: string;
  title: string;
  customer: Customer;
  functionalPermissions: (SPPermission | string)[];
  userPermissions: (SPPermission | string)[];
  login: string;
  note: string;
  language: string;
  roles: string[];
  units: string;
  gasUnit: string;
  fuelConsumptionType: string;
  vehicleIdentifier: string;
  nationality: string;
  timeModify: string;
  hasPicture: boolean;
  hasUserAccount: boolean;
  timeLocked: boolean;
}

export interface Customer {
  externalCustomerReference: string;
  name: string;
  vatNumber: string;
  language: string;
  country: string;
  externalCountryReference: string;
  customerReference: string;
  changedBy: string;
  startOfWeek: string;
  units: string;
  fuelConsumptionType: string;
  timeZone: string;
  vehicleIdentifier: string;
  utcDiff: number;
  gasUnit?: string;
}
