import { InjectionToken } from '@angular/core';

export const ENVIRONMENT_TOKEN = new InjectionToken<Environment>('APP_ENVIRONMENT');

export interface Environment {
  administration: string;
  adobeAnalyticsSourceURL: string;
  apiKey: string;
  auth: string;
  crossStroageHub: string;
  datadog: {
    appId: string;
    appName: string;
    clientToken: string;
  };
  facadeBaseAws: string;
  features: string;
  graphqlCheckListURL: string;
  hereMapsApiKey: string;
  imageApi: string;
  imageUploadURLPrefix: string;
  keycloak: {
    issuer: string;
    realm: string;
    clientId: string;
  };
  launchdarklyClientId: string;
  mopinionDeploymentId: string;
  myScaniaFmpURL: string;
  myScaniaURL: string;
  name: string;
  production: boolean;
  serviceBaseServicePlanning: string;
  xClientName: string;
}
