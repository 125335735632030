import { Component, Input } from '@angular/core';
import { Status } from '@shared/models/status-type.model';
@Component({
  selector: 'status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  DEFAULT_ICON = 'fas fa-circle';
  @Input() type: Status = Status.DEFAULT;
  @Input() icon = this.DEFAULT_ICON;
  @Input() label = '';
  @Input() description = '';
  @Input() hidden = false;

  constructor() {}
}
