import { inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT_TOKEN } from '@core/models/environment.model';
import { LDFlag, UserProfile } from '@core/models/user-profile.model';
import { AnalyticsEventData } from '@core/services/analytics/analytics';
import { CookieService } from '@core/services/cookie/cookie.service';
import { UserStoreService } from '@core/services/user-store/user-store.service';
import { datadogRum } from '@datadog/browser-rum';

@Injectable({ providedIn: 'root' })
export class DatadogService {
  private cookieService: CookieService;
  private env: Environment;
  private userStore: UserStoreService;
  private isDatadogEnabled = false;

  constructor() {
    this.cookieService = inject(CookieService);
    this.env = inject(ENVIRONMENT_TOKEN);
    this.userStore = inject(UserStoreService);
  }

  setUserContext(user: UserProfile) {
    datadogRum.setUser({
      id: user.externalStaffReference,
      name: user.customer.name,
      language: user.language,
      customerReference: user.customer.externalCustomerReference
    });
  }

  private shouldRunDatadog() {
    // do not track localhost
    if (window.location.hostname === 'localhost') {
      return;
    }

    // check for cookie consent
    const hasCookieConsent = this.cookieService.getConsentCookiePreferences()?.performance;
    if (!hasCookieConsent) {
      return false;
    }

    // check if datadog flag is enabled for current context
    const isDatadogEnabled = this.userStore.getLDFlagValue(LDFlag.DATADOG);
    if (!isDatadogEnabled) {
      return false;
    }

    return true;
  }

  init(userProfile: UserProfile) {
    if (!this.shouldRunDatadog()) {
      return;
    }

    datadogRum.init({
      applicationId: this.env.datadog.appId,
      clientToken: this.env.datadog.clientToken,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.eu',
      service: this.env.datadog.appName,
      env: this.env.name,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      trackViewsManually: true,
      defaultPrivacyLevel: 'mask'
    });

    this.setUserContext(userProfile);
    this.isDatadogEnabled = true;

    // eslint-disable-next-line no-console
    console.log('[Datadog] Datadog ready');
  }

  pushPageViewEvent(viewContext: string) {
    if (!this.isDatadogEnabled) {
      return;
    }

    datadogRum.startView(viewContext);
  }

  pushEvent(eventName: string, eventData: AnalyticsEventData = {}) {
    if (!this.isDatadogEnabled) {
      return;
    }

    datadogRum.addAction(eventName, eventData);
  }
}
