import { CommonModule } from '@angular/common';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import { initializeKeycloak } from '@core/init/keycloak-init.factory';
import { httpInterceptorProviders } from '@core/interceptors';
import { ENVIRONMENT_TOKEN, Environment } from '@core/models/environment.model';
import { AuthService } from '@core/services/auth/auth.service';
import { LaunchDarklyService } from '@core/services/launchdarkly/launchdarkly.service';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

@NgModule({
  imports: [CommonModule, KeycloakAngularModule],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  public static forRoot(environment: Environment): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: initializeKeycloak,
          multi: true,
          deps: [KeycloakService, AuthService, ENVIRONMENT_TOKEN]
        },
        { provide: ENVIRONMENT_TOKEN, useValue: environment },
        AuthService,
        httpInterceptorProviders,
        KeycloakService,
        LaunchDarklyService
      ]
    };
  }
}
