import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { fromEvent, Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';

export type Base64 = string | ArrayBuffer;

@Injectable({
  providedIn: 'root'
})
export class ImageHandlerService {
  /** Store a local cache of base64 images, indexed by URL.
   * For local images that have not been uploaded yet, the index value is the filename.
   * We use a counter to prevent data duplication. */
  private imageCache: Map<string, { data: Base64; count: number }> = new Map();
  constructor(private http: HttpClient) {}

  /** Fetch image as blob from an URL. We need to fetch each image manually
   * in order to add the authentication header to the request. */
  fetchBlobImage(imageURL: string): Observable<Blob> {
    return this.http.get(imageURL, { responseType: 'blob' });
  }

  /** Get base64 data from a blob, usually selected by user via input.
   * This is useful for caching images that are not yet uploaded */
  getB64FromBlob(image: Blob): Observable<Base64> {
    if (!image) {
      return of(null);
    }
    const reader = new FileReader();
    reader.readAsDataURL(image);
    return fromEvent(reader, 'load').pipe(
      // complete the observable after the first emission
      // as the base64 from a blob is an one-time result, no subsequent events are emitted
      // and `fromEvents`will not complete after the first emission
      // blocking use-cases like setting the loader to false after the observable completes
      take(1),
      map(() => reader.result)
    );
  }

  /** Get base64 data from an image URL. This is useful for caching images */
  getB64FromURL(imageURL: string): Observable<Base64> {
    return (
      // fetch image as blob
      this.fetchBlobImage(imageURL)
        // and convert the result to base64
        .pipe(mergeMap((blobImage: Blob) => this.getB64FromBlob(blobImage)))
    );
  }

  /** Get the cached base64 data for an image url, or null if it doesn't exist */
  getCachedImage(imageURL: string): Base64 {
    if (this.imageCache.has(imageURL)) {
      return this.imageCache.get(imageURL).data;
    }
    return null;
  }

  /** Cache base64 data for an image url.
   * If the image is already cached, increase the counter.
   * This will help in the event that the same image is being used
   * in multiple places, and needs to be cached multiple times */
  cacheImage(imageURL: string, data: Base64): void {
    if (this.imageCache.has(imageURL)) {
      const existingData = this.imageCache.get(imageURL);
      this.imageCache.set(imageURL, { ...existingData, count: existingData.count + 1 });
    } else {
      this.imageCache.set(imageURL, { data, count: 1 });
    }
  }

  /** Remove the cached base64 data for an image URL.
   * If the image has been cached multiple times, just decrease the counter,
   * otherwise remove the entire entry in the cache. */
  removeCachedImage(imageURL: string): void {
    if (!this.imageCache.has(imageURL)) {
      return;
    }
    const existingData = this.imageCache.get(imageURL);

    if (existingData.count > 1) {
      this.imageCache.set(imageURL, { ...existingData, count: existingData.count - 1 });
    } else {
      this.imageCache.delete(imageURL);
    }
  }
}
