import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

type OnChange = (value: string) => void;
type OnTouched = () => void;

@Component({
  selector: 'dynamic-textarea',
  templateUrl: './dynamic-textarea.component.html',
  styleUrls: ['./dynamic-textarea.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: DynamicTextareaComponent, multi: true }]
})
export class DynamicTextareaComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() id: string;
  /** this input is not named `maxLength` to keep consistency with the textarea `maxlength` attribute */
  @Input() maxlength: number;
  @Input() size = 'sm'; // md | lg
  @Input() showGreyInput = false;
  @ViewChild('textarea', { static: true }) textarea: ElementRef<HTMLDivElement>;

  sub: Subscription;

  onChange: OnChange = () => null;
  onTouched: OnTouched = () => null;

  get value(): string {
    return this.textarea.nativeElement.innerText;
  }

  set value(value: string) {
    this.textarea.nativeElement.innerText = value;
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(onChange: OnChange): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: OnTouched): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.textarea.nativeElement.setAttribute('contenteditable', 'false');
    } else {
      this.textarea.nativeElement.setAttribute('contenteditable', 'true');
    }
  }

  ngOnInit(): void {
    this.sub = this.handleInputEvents();

    // if there is a valid max length specified and the user input exceeds the max length
    // prevent the user from adding content to the textarea unless they remove something
    this.sub.add(this.handleKeypressEvents());

    // override the `paste` event to prevent the user from pasting formatted text into the textarea
    // and to also prevent the user from adding contet to the textarea if the length exceeds the max length
    this.sub.add(this.handlePasteEvents());
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  /** handle all input events from the user.
   * input events are also triggered by keypress events */
  private handleInputEvents() {
    return fromEvent(this.textarea.nativeElement, 'input').subscribe(() => {
      let value = this.value;

      // if there is a valid max length specified
      // then trim the new value to specified max length
      if (!!this.maxlength && value.length > this.maxlength) {
        value = value.slice(0, this.maxlength);
        this.value = value;
      }

      this.onChange(value);
      this.onTouched();
    });
  }

  /** prevent keypress events if maxlength is reached
   * so the user cannot input more data */
  private handleKeypressEvents() {
    return fromEvent(this.textarea.nativeElement, 'keypress')
      .pipe(filter(() => !!this.maxlength && this.value.length >= this.maxlength))
      .subscribe((event) => event.preventDefault());
  }

  /** override the default paste event behaviour to prevent pasting formatted data.
   * also prevent pasting data if maxcontent would be exceeded */
  private handlePasteEvents() {
    return fromEvent(this.textarea.nativeElement, 'paste').subscribe((event: ClipboardEvent) => {
      event.preventDefault();

      const pasteValue = event.clipboardData.getData('text');
      if (!!this.maxlength && this.value.length + pasteValue.length >= this.maxlength) {
        return;
      }

      const selection = window.getSelection();

      const newContent = document.createElement('span');
      newContent.innerText = pasteValue;

      selection.getRangeAt(0).insertNode(newContent);
      selection.collapseToEnd();

      this.onChange(this.value);
      this.onTouched();
    });
  }
}
