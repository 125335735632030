import { Component, Input } from '@angular/core';

export interface InformationBannerAction {
  label: string;
  redirectTo: string;
}

@Component({
  selector: 'information-banner',
  templateUrl: './information-banner.component.html',
  styleUrls: ['./information-banner.component.scss']
})
export class InformationBannerComponent {
  @Input() action: InformationBannerAction;
  @Input() content: string;
  @Input() header: string;
  @Input() icon: string;
}
