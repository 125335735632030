import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ENVIRONMENT_TOKEN, Environment } from '@core/models/environment.model';
import H from '@here/maps-api-for-javascript';
import { TranslateService } from '@ngx-translate/core';
import {
  DEFAULT_ICON_HTML,
  MAP_DEFAULTS,
  MAP_LANGUAGES,
  MAP_TILE,
  SCALEBAR_STYLE
} from '@shared/constants/map.constants';
import { MapLanguage, MapLocation, MapMarkerFactory } from '@shared/models/map';

@Component({
  selector: 'map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('map') mapElement: ElementRef;
  @Input() center: MapLocation = MAP_DEFAULTS.coordinates;
  @Input() locations: MapLocation[] = [];
  @Input() markerFactory: MapMarkerFactory = null;
  @Output() locationSelect: EventEmitter<MapLocation> = new EventEmitter();
  map: H.Map;
  platform: H.service.Platform = null;
  defaultLayers = null;
  domMarkers: H.map.Marker[] = [];

  resizeMapViewport = () => this.map.getViewPort().resize();

  constructor(@Inject(ENVIRONMENT_TOKEN) private env: Environment, private translateService: TranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['center']?.currentValue) {
      const coordinates = {
        latitude: changes['center'].currentValue.latitude,
        longitude: changes['center'].currentValue.longitude,
        zoomFactor: changes['center'].currentValue.zoomFactor || MAP_DEFAULTS.zoom.withCoordinates
      };

      this.setCenterCoordinates(coordinates);
    }

    if (changes['locations']?.currentValue) {
      this.displayLocations(changes['locations'].currentValue);
    }
  }

  ngAfterViewInit(): void {
    this.buildMap();
    this.displayLocations(this.locations);
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.resizeMapViewport);
  }

  setCenterCoordinates(coordinates: MapLocation) {
    if (this.map && this.areCoordinatesValid(coordinates)) {
      this.map
        .setCenter({ lat: coordinates.latitude, lng: coordinates.longitude })
        .setZoom(coordinates.zoomFactor || MAP_DEFAULTS.zoom.withCoordinates);
    }
  }

  selectLocation(location: MapLocation) {
    this.locationSelect.emit(location);
  }

  getMapLanguage(): MapLanguage {
    const currentLang = this.translateService.defaultLang;
    const defaultMapLang = { Culture: '', HereCode: '', HereUiCode: '', Label: '' };
    return MAP_LANGUAGES.find((lang) => lang.Culture === currentLang) || defaultMapLang;
  }

  createDefaultMarker(lat: number, lng: number, data?: any): H.map.Marker {
    const markerIcon = new H.map.DomIcon(DEFAULT_ICON_HTML);
    return new H.map.DomMarker({ lat, lng }, { icon: markerIcon, data });
  }

  getMarker(latitude: number, longitude: number, data?: MapLocation): H.map.Marker {
    // if there's a marker factory specified, use that instead
    const marker = this.markerFactory
      ? this.markerFactory.create(latitude, longitude, data)
      : this.createDefaultMarker(latitude, longitude, data);

    marker.addEventListener('tap', () => this.selectLocation(marker.getData()));

    return marker;
  }

  createPlatform(): H.service.Platform {
    if (this.platform) {
      return this.platform;
    }
    this.platform = new H.service.Platform({ apikey: this.env.hereMapsApiKey });
    return this.platform;
  }

  createDefaultMapLayers(): any {
    if (this.defaultLayers) {
      return this.defaultLayers;
    }
    const platform = this.createPlatform();
    const mapLanguage = this.getMapLanguage();
    this.defaultLayers = platform.createDefaultLayers(MAP_TILE.size, MAP_TILE.ppi, mapLanguage.HereCode);
    return this.defaultLayers;
  }

  createMap(center: H.geo.IPoint, zoom: number): H.Map {
    const defaultLayers = this.createDefaultMapLayers();
    return new H.Map(this.mapElement.nativeElement, defaultLayers.raster.normal.map, { center, zoom });
  }

  createMapUI(map: H.Map): H.ui.UI {
    return H.ui.UI.createDefault(map, this.createDefaultMapLayers(), this.getMapLanguage().HereUiCode);
  }

  createMapBehavior(map: H.Map): H.mapevents.Behavior {
    return new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
  }

  buildMap(): void {
    const validCoordinates = this.areCoordinatesValid(this.center);
    // center map to specified coordinates if they are valid, otherwise center to default coordinates
    const center = validCoordinates
      ? { lat: this.center.latitude, lng: this.center.longitude }
      : { lat: MAP_DEFAULTS.coordinates.latitude, lng: MAP_DEFAULTS.coordinates.longitude };

    // get the zoom level, based on the existence of valid coordinates
    const zoom = validCoordinates ? MAP_DEFAULTS.zoom.withCoordinates : MAP_DEFAULTS.zoom.noCoordinates;

    this.map = this.createMap(center, zoom);

    // resize the map viewport according with the screen size to fix uncentered coordinates
    setTimeout(this.resizeMapViewport);
    window.addEventListener('resize', this.resizeMapViewport);

    this.prepareMap(this.map);
  }

  prepareMap(map: H.Map): void {
    // disable franctional zooming, tilt and heading
    const behavior = this.createMapBehavior(map);
    behavior.disable(H.mapevents.Behavior.Feature.FRACTIONAL_ZOOM);
    behavior.disable(H.mapevents.Behavior.Feature.TILT);
    behavior.disable(H.mapevents.Behavior.Feature.HEADING);

    // remove map UI zoom and settings controls
    const mapUI = this.createMapUI(map);
    mapUI.removeControl('zoom');
    mapUI.removeControl('mapsettings');

    // style scalebar
    const scalebar = mapUI.getControl('scalebar');
    scalebar.setAlignment(H.ui.LayoutAlignment.BOTTOM_RIGHT);
    scalebar.getElement().style.position = SCALEBAR_STYLE.position;
    scalebar.getElement().style.bottom = SCALEBAR_STYLE.bottom;
    scalebar.getElement().style.right = SCALEBAR_STYLE.right;
  }

  displayLocations(locations: MapLocation[]): void {
    // do nothing if the map is not yet created
    if (!this.map) {
      return;
    }
    this.locations = locations;
    // redisplay all markers
    this.domMarkers.forEach((marker) => {
      marker.dispose();
      this.map.removeObject(marker);
    });
    this.domMarkers = locations.map((loc) => this.getMarker(loc.latitude, loc.longitude, loc));
    this.map.addObjects(this.domMarkers);
  }

  areCoordinatesValid(coordinates: MapLocation): boolean {
    return !!coordinates && !isNaN(coordinates.latitude) && !isNaN(coordinates.longitude);
  }
}
