import { Component, inject, Injector, OnInit } from '@angular/core';
import { ANALYTICS_VIEW_CONTEXT } from '@core/services/analytics/analytics';
import { AnalyticsService } from '@core/services/analytics/analytics.service';

/** Components that use `AnalyticsEvent` decorator need to extend this class
 * in order to expose the `Injector` reference to the decorator, so it can have access
 * to the analytics service, and also the analytics view context.
 *
 * @example
 *
 * *@Component*({...})
 * class MyActionComponent extends AnalyticsActionComponent {
 *  ...
 *
 *  *@AnalyticsEvent*({ eventName: '...' })
 *   doSomeAction() {...}
 * }
 */
export class AnalyticsProvider {
  protected injector = inject(Injector);
}

/** Components that represent a view need to extend this class and provide a view context
 * so that the view change event is correctly registered within analytics.
 * @example
 *
 * *@Component*({
 *   providers: [{ provide: ANALYTICS_VIEW_CONTEXT, useValue: 'sp:my-view:nested-view' }]
 * })
 * class MyCustomView extends AnalyticsPageViewComponent {}
 */
@Component({ template: '' })
export class AnalyticsViewComponent extends AnalyticsProvider implements OnInit {
  protected analyticsService = inject(AnalyticsService);
  protected analyticsViewContext = inject(ANALYTICS_VIEW_CONTEXT);

  public ngOnInit() {
    this.analyticsService.pushPageViewEvent(this.analyticsViewContext);
  }
}
