import { Component, Input } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

export interface ToastMessageRedirect {
  link: string;
  label: string;
}

@Component({
  selector: 'toast-message',
  templateUrl: './toast-message.component.html'
})
export class ToastMessageComponent extends Toast {
  @Input() header: string;
  @Input() content: string;
  @Input() icon: string;
  @Input() redirect: ToastMessageRedirect;
  @Input() translateParams: any;

  constructor(
    protected override toastrService: ToastrService,
    public override toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);
  }
}
