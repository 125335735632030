import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentHostDirective } from '@shared/directives/component-host.directive';
import {
  ModalButton,
  ModalConfig,
  ModalContentComponent,
  ModalEventName,
  ModalEventType,
  ModalResolution
} from '@shared/models/modal';
import { ButtonType } from '@teamsp/components/button';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent<
  CType extends ModalContentComponent<RType, EName>,
  RType,
  EName extends ModalEventName = ModalEventName
> implements OnInit
{
  @ViewChild(ComponentHostDirective, { static: true }) componentHost: ComponentHostDirective;
  @Input() config: ModalConfig<CType, RType, EName> = {};
  @Output() modalClose: EventEmitter<ModalResolution<RType, EName>> = new EventEmitter();
  @Output() contentComponentInit: EventEmitter<CType> = new EventEmitter();

  disabledEvents: { [key in ModalEventType]: boolean } = {
    [ModalEventType.NEGATIVE]: false,
    [ModalEventType.POSITIVE]: false,
    [ModalEventType.NEUTRAL]: false,
    [ModalEventType.DISMISS]: false
  };

  activeComponent: CType;

  ButtonType = ButtonType;

  // template usage
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public ModalEventType = ModalEventType;

  constructor(public activeModal: NgbActiveModal) {}

  /** emit modal action with event type, custom event name and optionally resolution data */
  async action(eventType: ModalEventType, eventName: EName, data?: RType): Promise<void>;

  /** emit modal action with event type and resolution data */
  async action(eventType: ModalEventType, data: RType): Promise<void>;

  /** emit modal action with event type */
  async action(eventType: ModalEventType): Promise<void>;

  /** method implementation declares all possibilities of arguments and needs to determine at runtime what it receives */
  async action(eventType: ModalEventType, eventNameOrData?: EName | RType, data?: RType) {
    // extract actionData and eventName from eventNameOrData param due to method overloading
    const actionData = data || typeof eventNameOrData === 'object' ? (eventNameOrData as RType) : null;
    const eventName = typeof eventNameOrData !== 'object' ? (eventNameOrData as EName) : null;
    // if no data is directly emitted via `modalAction` method
    // use the `valueAccessor` for the modal content component if it exists to emit data to parent
    const resolutionData = actionData || (this.activeComponent && this.activeComponent.valueAccessor());

    const goThrough = () => {
      this.modalClose.emit({
        eventType,
        ...(resolutionData ? { data: resolutionData } : null),
        ...(eventName ? { eventName } : null)
      });
      this.activeModal.close();
    };

    if (!this.config.contentComponent || !this.activeComponent) {
      return goThrough();
    }
    // if a content component is specified, we need to check if there is a hook
    // that can prevent the modal from closing
    // use Promise.resolve to normalize the truth value (either boolean or Promise<boolean>)
    const shouldGoThrough = await Promise.resolve(this.shouldEventGoThrough(eventType, eventName));

    if (shouldGoThrough) {
      goThrough();
    }
  }

  /**
   * Validate if a modal event should go through by calling the appropriate action hook based on the event type.
   * Note: we could add support for `Observable<boolean>` here as well.
   * @example
   *  class ActiveComponent {
   *    //...
   *    onPositiveAction(eventName: CustomEventName) {
   *      if (eventName === CustomEventName.NO_GO) {
   *        return false;
   *      }
   *      return true;
   *    }
   *  }
   *
   *  shouldEventGoThrough(ModalEventType.POSITIVE, CustomEventName.NO_GO) = false;
   *  shouldEventGoThrough(ModalEventType.POSITIVE, CustomEventName.COMPLETE) = true;
   * */
  shouldEventGoThrough(eventType: ModalEventType, eventName?: EName): boolean | Promise<boolean> {
    switch (eventType) {
      case ModalEventType.POSITIVE:
        return this.activeComponent.onPositiveAction(eventName);
      case ModalEventType.NEGATIVE:
        return this.activeComponent.onNegativeAction(eventName);
      case ModalEventType.NEUTRAL:
        return this.activeComponent.onNeutralAction(eventName);
      case ModalEventType.DISMISS:
        return this.activeComponent.onDismissAction(eventName);
      default:
        return true;
    }
  }

  setModalEventDisabled(eventType: ModalEventType, disabled: boolean) {
    this.disabledEvents[eventType] = disabled;
  }

  addModalAction(eventType: ModalEventType, buttonConfig: ModalButton<EName>): void;
  addModalAction(eventType: ModalEventType, label: string, icon?: string): void;
  addModalAction(eventType: ModalEventType, labelOrConfig: string | ModalButton<EName>, icon?: string): void {
    const buttonConfig: ModalButton<EName> =
      typeof labelOrConfig === 'object' ? labelOrConfig : { text: labelOrConfig, icon };
    this.config.buttons = { ...this.config.buttons, [eventType]: buttonConfig };
  }

  removeModalAction(eventType: ModalEventType) {
    if (this.config.buttons && this.config.buttons[eventType]) {
      delete this.config.buttons[eventType];
    }
  }

  ngOnInit() {
    // if the modal content is a string, no action is needed
    if (!this.config.contentComponent) {
      return;
    }

    const viewContainer = this.componentHost.viewContainerRef;
    viewContainer.clear();
    const compRef = viewContainer.createComponent(this.config.contentComponent, { injector: this.config.injector });

    // link the setModalEventDisabled method to be able to set a modal event disabled state
    // from within the modal content component
    compRef.instance.setModalEventDisabled = (event, disabled) => this.setModalEventDisabled(event, disabled);

    // link addModal method to be able to add new modal actions
    // from within the modal content component
    compRef.instance.addModalAction = (
      event: ModalEventType,
      labelOrConfig: string | ModalButton<EName>,
      icon?: string
    ) => {
      if (typeof icon === 'string') {
        return this.addModalAction(event, labelOrConfig as string, icon);
      }
      this.addModalAction(event, labelOrConfig as ModalButton<EName>);
    };

    // link removeModal to be able to remove any modal action
    // from within the modal content component
    compRef.instance.removeModalAction = (event) => this.removeModalAction(event);

    // link the close method to be able to close the modal
    // from within the modal content component
    compRef.instance.modalAction = (eventType: ModalEventType, eventNameOrData?: EName | RType, data?: RType) => {
      if (!!data) {
        return this.action(eventType, eventNameOrData as EName, data);
      }

      return this.action(eventType, eventNameOrData as RType);
    };

    // set the modal content component inputs
    if (this.config.contentComponentInputs) {
      Object.assign(compRef.instance, this.config.contentComponentInputs);
    }

    this.activeComponent = compRef.instance;
    this.contentComponentInit.emit(compRef.instance);
  }
}
