import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SwUpdate, UnrecoverableStateEvent, VersionEvent } from '@angular/service-worker';
import { from, interval } from 'rxjs';
import { filter, mergeMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  constructor(private swUpdate: SwUpdate, private router: Router) {}

  private onVersionUpdateEvent(event: VersionEvent) {
    // we are only interested in the `VERSION_READY` event
    if (event.type !== 'VERSION_READY') {
      return;
    }

    console.warn(`[SW]: Found a new version. Reloading on next route change.`);
    this.router.events
      .pipe(
        filter((routerEvent) => routerEvent instanceof NavigationStart),
        take(1),
        mergeMap(() => from(this.swUpdate.activateUpdate()))
      )
      // no need to unsubscribe as the app will be reloaded
      .subscribe(() => window.location.reload());
  }

  private onUnrecoverableEvent(_event: UnrecoverableStateEvent) {
    // if the service worker reaches an unrecoverable state
    // we need to refresh the page
    window.location.reload();
  }

  public listen() {
    if (!this.swUpdate.isEnabled) {
      return;
    }
    console.info('[SW] Service worker enabled and listening for updates.');

    const everyInterval$ = interval(2 * 60 * 1000); // every 2 minutes

    everyInterval$
      .pipe(tap(() => console.info('[SW] checking for updates')))
      .subscribe(() => this.swUpdate.checkForUpdate());

    // listen for state update events
    this.swUpdate.versionUpdates.subscribe((event: VersionEvent) => this.onVersionUpdateEvent(event));
    this.swUpdate.unrecoverable.subscribe((event: UnrecoverableStateEvent) => this.onUnrecoverableEvent(event));
  }
}
