/* eslint-disable id-blacklist */
export interface DealerAddress {
  street: string;
  city: string;
  postalCode: string;
}

export interface DealerPhone {
  type: string;
  number: string;
  comment: string;
}

export interface DealerAvailableService {
  code: string;
  title: string;
  categories: string[];
}

export interface DealerOpeningHours {
  days: string[];
  openTimes: DealerOpenTimes[];
}

export interface DealerOpenTimes {
  timeFrom: string;
  timeTo: string;
}

export interface Dealer {
  id: string;
  name: string;
  email: string;
  WWW?: string;
  address: DealerAddress;
  location: { longitude: number; latitude: number };
  phones: DealerPhone[];
  availableServices: DealerAvailableService[];
  countryCode: string;
  workshopReference: string;
  openingHours?: DealerOpeningHours[];
}

export const SERVICE_CODE_I18N_KEY = {
  '24hassistance': 'DygnetRunt_Assistans',
  accidentrepairs: 'Olycksreparation',
  adrvehicleservice: 'ServiceAvADR_Fordon',
  alternativefuelandhybrids: 'ServiceFörAlternativaBränslenOchHybridfordon',
  analoguetachographinspectionandrepairs: 'InspektionOchReparationAvAnalogFärdskrivare',
  bussales: 'FörsäljningAvBussar',
  busservice: 'ServiceAvBuss',
  digitaltachographdatadownload: 'NedladdningAvDataFrånDigitalFärdskrivare',
  digitaltachographlegalinspection: 'LagstadgadInspektionAvDigitalFärdskrivare',
  gasvehicleservicecng: 'SP_ServiceCNG',
  gasvehicleservicelng: 'SP_ServiceLNG',
  highvoltagediagnoseservice: 'SP_ServiceVCBDiagnostics',
  highvoltagerepairservice: 'SP_ServiceVCBRepair',
  hookliftservice: 'SP_ServiceHookLift',
  hydraulicsbodyworkservice: 'ServiceAvHydraulik_Påbyggnad',
  industrialpowergenerationenginessales: 'FörsäljningAvIndustri_OchGeneratormotorer',
  industrialpowergenerationenginesservice: 'ServiceAvIndustri_OchGeneratormotorer',
  marineenginessales: 'FörsäljningAvMarinmotorer',
  marineenginesservice: 'ServiceAvMarinmotorer',
  rollerbraketest: 'Rullbromsprovning',
  scaniadrivercoaching: 'ScaniaFörarcoachning',
  scaniadrivertraining: 'ScaniaFörarutbildning',
  scaniagensetssales: 'FörsäljningAvScaniaGenset',
  scaniagensetsservices: 'ServiceAvScaniaGenset',
  scaniaminingsales: 'SP_MiningSales',
  scaniaminingservices: 'SP_ServiceMining',
  scaniapartssales: 'FörsäljningAvScaniaReservdelar',
  statutoryinspection: 'SP_StatuoryInspection',
  tailliftservices: 'ServiceAvBakgavellyft',
  tarpaulinservice: 'ServiceAvPresenning',
  tollcollectinstallationandservice: 'ServiceOchInstallationAvTollCollect',
  towingandrecoveryservice: 'Bärgning',
  trailerservice: 'ServiceAvSläpfordon',
  transportrefrigerationunitservice: 'ServiceAvFordonMedKylaggregat',
  trucksales: 'FörsäljningAvLastbilar',
  truckservice: 'ServiceAvLastbil',
  tyreservice: 'ServiceAvDäck',
  usedvehiclessales: 'FörsäljningAvBegagnadeFordon',
  vehiclerental: 'Fordonsuthyrning',
  vehiclewashing: 'TvättningAvFordon',
  wastecollector: 'SP_WasteCollector'
};
