import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { QtyService } from '@shared/services/qty/qty.service';

@Pipe({
  name: 'qtyTime'
})
export class QtyTimePipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe, private translate: TranslateService, private qtyService: QtyService) {}

  transform(value: number, fraction?: any): string {
    const converted = this.qtyService.toTargetTimeUnit(value);
    const formatted = this.decimalPipe.transform(converted, fraction || QtyService.DIGITINFO_NO_FRACTION);
    const translationKey = this.qtyService.getTimeTranslationKey();
    return this.translate.instant(translationKey, formatted);
  }
}
