import { HttpResponse } from '@angular/common/http';
import { FEGatewayResponse } from '@core/models/frontend-gateway-response.model';
import { NOTIFICATION_V2 } from '@shared/constants/request-url-types';
import { RequestMapper } from '@shared/mappers/mapper';

export class NotificationRequestMapper extends RequestMapper {
  override mapResponse(response: HttpResponse<FEGatewayResponse<(Record<any, any> & { timeSaved: string })[]>>) {
    if (!response.url.includes(NOTIFICATION_V2.URL) || response.url.includes('notification-dismissals')) {
      return response;
    }

    const body = response.body.data.map((notification) => ({ ...notification, updatedAt: notification.timeSaved }));
    return response.clone({ body: { data: body } });
  }
}
