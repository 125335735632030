import { Component } from '@angular/core';
import { NoNetworkService } from '@shared/services/no-network/no-network.service';

@Component({
  selector: 'app-no-internet-toastr',
  templateUrl: './no-internet-toastr.component.html',
  styleUrls: ['./no-internet-toastr.component.scss']
})
export class NoInternetToastrComponent{
  public showOfflineMessage = true;
  constructor(private noNetworkService: NoNetworkService) {  }

  public toggleOfflineMessage(): void {
    this.showOfflineMessage = !this.showOfflineMessage;
  }

  isNetworkAvailable = () => this.noNetworkService.isNetworkAvailable();
}
