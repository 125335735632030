import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: ToggleComponent }]
})
export class ToggleComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() id = this.generateId();

  toggleControl: FormControl = new FormControl(true);
  private sub: Subscription;

  private prevEmit = true;

  private onChange: (value: boolean) => void;
  private onTouched: () => void;

  constructor() {}

  writeValue(value: boolean) {
    this.prevEmit = value;
    this.toggleControl.patchValue(value, { emitEvent: false });
  }

  registerOnChange(onChange: (value: boolean) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  private generateId() {
    return `toggle-input-${Math.floor(Math.random() * 100)}`;
  }

  private emitValue(value: boolean) {
    if (value !== this.prevEmit) {
      this.onChange(value);
      this.onTouched();
      this.prevEmit = value;
    }
  }

  ngOnInit() {
    this.sub = this.toggleControl.valueChanges.subscribe((value) => this.emitValue(value));
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
