import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HashRouteRedirectGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): UrlTree {
    // keep compatibility with hash routing and redirect to corresponding route without hashing
    if (window.location.hash.startsWith('#/')) {
      window.location.href = window.location.hash.slice(1);
      return null;
    }

    const defaultRoute: string = route.data['defaultRoute'] || '';
    return this.router.createUrlTree([defaultRoute]);
  }
}
