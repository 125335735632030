export enum Status {
    DANGER = 'danger',
    DEFAULT = 'default',
    INFO = 'info',
    SUCCESS = 'success',
    WARNING = 'warning',
    PRIMARY = 'primary'
}

export interface IStatus {
  type: Status;
  label: string;
  description?: string;
}
