import { Equipment, EquipmentDetails, RawEquipment } from '@admin-facade/models/equipment-data.model';
import { EquipmentGroup } from '@admin-facade/models/equipment-group.model';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_TOKEN, Environment } from '@core/models/environment.model';
import { UserStoreService } from '@core/services/user-store/user-store.service';
import { ImageHandlerService } from '@shared/components/image-handler/image-handler.service';
import { vehicleIdentifierMap } from '@shared/constants/constants';
import { EQUIPMENT, EQUIPMENT_GROUP } from '@shared/constants/request-url-types';
import { Observable } from 'rxjs';
import { map, mergeMap, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {
  private baseUrl = `${this.env.facadeBaseAws}/api`;
  private baseUrlEquipment = `${this.baseUrl}/${EQUIPMENT.URL}`;
  private imageUrl = `${this.env.imageApi}/v1`;
  constructor(
    @Inject(ENVIRONMENT_TOKEN) private env: Environment,
    private http: HttpClient,
    private imageHandlerService: ImageHandlerService,
    private userService: UserStoreService
  ) {}

  private getEquipmentList(): Observable<RawEquipment[]> {
    return this.http.get<RawEquipment[]>(this.baseUrlEquipment);
  }

  mapEquipmentImagesAndLabels(equipmentList: RawEquipment[]): Equipment[] {
    return equipmentList.map((equipment) => this.mapEquipmentCoreInfo(equipment));
  }

  mapEquipmentCoreInfo(equipment: RawEquipment): Equipment {
    return {
      ...equipment,
      image: this.getEquipmentImageSrc(equipment),
      identifierTexts: this.getEquipmentIdentifierText(equipment),
      label: this.getEquipmentIdentifierText(equipment)[0]
    };
  }

  getEquipmentImageSrc(eq: RawEquipment): string {
    const equipmentIcon = `${this.imageUrl}/equipment/icon/${eq.defaultPictureId}/img`;
    const equipmentImage = `${this.imageUrl}/equipment/${eq.externalEquipmentReference}/img`;
    return eq.hasPicture ? equipmentImage : equipmentIcon;
  }

  /** get a list of identifiers for the specified equipment
   * (chassis number, registration number or equipment alias)
   * ordered according to user's preferences */
  getEquipmentIdentifierText(equipment: EquipmentDetails): Array<string> {
    const userProfile = this.userService.profile;
    if (userProfile && userProfile.vehicleIdentifier === vehicleIdentifierMap.alias) {
      return [equipment?.alias, equipment?.registrationNumber, equipment?.chassisNumber].filter((el) => !!el);
    } else if (userProfile && userProfile.vehicleIdentifier === vehicleIdentifierMap.chassisNumber) {
      return [equipment?.chassisNumber, equipment?.registrationNumber, equipment?.alias].filter((el) => !!el);
    }
    return [equipment?.registrationNumber, equipment?.chassisNumber, equipment?.alias].filter((el) => !!el);
  }

  getData(): Observable<Equipment[]> {
    return this.getEquipmentList().pipe(
      map((rawEquipment) => this.mapEquipmentImagesAndLabels(rawEquipment)),
      shareReplay()
    );
  }

  getEquipmentByRef(externalRef: string): Observable<RawEquipment> {
    const endpointUrl = `${this.baseUrl}/${EQUIPMENT.URL}/${externalRef}`;
    return this.http.get<RawEquipment>(endpointUrl);
  }

  getEquipmentGroup(): Observable<EquipmentGroup[]> {
    const endpointUrl = `${this.baseUrl}/${EQUIPMENT_GROUP.URL}`;
    return this.http.get<any>(endpointUrl);
  }

  updateEquipment(data: RawEquipment): Observable<RawEquipment> {
    return this.http.put<RawEquipment>(this.baseUrlEquipment, data);
  }

  updateEquipmentVin(equipmentReference: string, vin: string): Observable<RawEquipment> {
    return this.getEquipmentByRef(equipmentReference).pipe(
      mergeMap((equipment) => this.updateEquipment({ ...equipment, vin }))
    );
  }

  getEquipmentImages(equipments: Equipment[]) {
    // filter unique image url data , most equipment has same image urls especially with icons
    const equipmentsWithUniqueImgURL = equipments.filter(
      (value, index) => equipments.findIndex((a) => a.image === value.image) === index
    );

    equipmentsWithUniqueImgURL.forEach((equipment) => {
      if (!this.imageHandlerService.getCachedImage(equipment.image)) {
        return this.imageHandlerService
          .getB64FromURL(equipment.image)
          .subscribe((data) => this.imageHandlerService.cacheImage(equipment.image, data));
      }
    });
  }
}
