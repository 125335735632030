import { HttpResponse } from '@angular/common/http';
import { BOOKING } from '@shared/constants/request-url-types';
import { RequestMapper } from '@shared/mappers/mapper';

export class BookingRequestMapper extends RequestMapper {
  override requestParamsMap: any = {
    bookingStatus: 'BookingStatus',
    endDate: 'End',
    equipmentGroupReference: 'EquipmentGroupRef',
    equipmentReference: 'ExternalEquipmentReference',
    startDate: 'Start'
  };

  override mapResponse(response: HttpResponse<{ data: { bookingsCount: number } }>) {
    if (response.url.includes(`${BOOKING.URL}/count`)) {
      return response.clone({ body: { data: response.body.data.bookingsCount || response.body.data } });
    }
    return response;
  }
}
