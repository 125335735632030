import { Injectable } from '@angular/core';
import { CookiePreferences } from '@myscania/scope';

@Injectable({ providedIn: 'root' })
export class CookieService {
  readCookies(): Map<string, string> {
    const cookies = document.cookie.split('; ');
    return new Map(cookies.map((cookie) => cookie.split('=') as [string, string]));
  }

  getCookie<T = string>(cookieName: string) {
    const cookiesMap = this.readCookies();

    if (!cookiesMap.has(cookieName)) {
      return null;
    }

    const cookieValue = cookiesMap.get(cookieName);
    try {
      return JSON.parse(cookieValue) as T;
    } catch (e) {
      return cookieValue as T;
    }
  }

  /** get MyScania consent cookie preferences */
  getConsentCookiePreferences(): CookiePreferences {
    const COOKIE_NAME = 'MyScania.CookieConsent';
    return this.getCookie<CookiePreferences>(COOKIE_NAME);
  }
}
