import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NoNetworkService } from '@shared/services/no-network/no-network.service';

@Component({
  selector: 'app-no-network',
  templateUrl: './no-network.component.html',
  styleUrls: ['./no-network.component.scss']
})
export class NoNetworkComponent implements OnInit {
  goBackTo: string;
  showNetworkAvailableBanner: boolean;
  constructor(private route: ActivatedRoute, private router: Router, private noNetworkService: NoNetworkService) { }

  ngOnInit(): void {
    this.goBackTo = this.route.snapshot.paramMap.get('goBackTo');
    this.noNetworkService.networkAvailabilityChange.subscribe(networkAvailable => {
      if(networkAvailable) {
        this.showNetworkAvailableBanner = true;
        setTimeout(() => this.router.navigate([this.goBackTo]), 5000)
      }
    })
  }
}
